import { Component, EventEmitter, inject, Output, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { catchError, finalize } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductMaster } from 'src/app/models/product-model';
import { RecommendationService } from 'src/app/services/recommendation.service';
import { RecommendationModel } from 'src/app/models/recommendation-model';
import { ComponentService } from 'src/app/services/component.service';
import { ComponentModel } from 'src/app/models/component-model';
@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [FormsModule, NgIf, DropdownModule, NgFor, NgClass],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.css'
})
export class SearchBarComponent {
  @Output() onSearch = new EventEmitter<object>();
  query: string = '';
  searchOptions: any[];
  selectedOption: string = 'nombre';
  filteredNames: string[] = [];
  productsMaster: ProductMaster[] = [];
  recommendations: RecommendationModel[] = [];
  components: ComponentModel[] = [];
  isSticky: boolean = false;

  private readonly _productService = inject(ProductService);
  private readonly _analyticsService = inject(AnalyticsService);
  private readonly _recommendation = inject(RecommendationService);
  private readonly _component = inject(ComponentService);

  placeholders = {
    'nombre': 'Ingrese nombre del medicamento',
    'dolencia': 'Ingrese nombre de la dolencia',
    'componente': 'Ingrese nombre del principio activo',
  };

  constructor(private router: Router, private route: ActivatedRoute) {
    this.searchOptions = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'Dolencia', value: 'dolencia' },
      { label: 'Principio Activo', value: 'componente' },
    ];

  }


  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.scrollY >= 272; // depends of the height of: nav bar + big logo
  }

  onChangeFilterType() {
    this._analyticsService.trackEvent("SEARCHBAR_TYPE_SELECTION", this.selectedOption, "USER_INTERACTION");
  }

  filter() {
    if (this.selectedOption === 'nombre') {
      if (this.query.length > 2) {
        this._productService.GetProductMasterByName(this.query).pipe(
          finalize(() => {
          }),
          catchError((error) => {
            throw error;
          })
        ).subscribe((resp: any) => {
          if (resp.Data.length > 0) {
            this.productsMaster = resp.Data;
            this.filteredNames = this.productsMaster.map(data => data.Name);
          }
        });
      } else {
        this.filteredNames = [];
      }
    } else if (this.selectedOption === 'dolencia') {
      if (this.query.length > 2) {
        this._recommendation.GetRecommendationByName(this.query).pipe(
          finalize(() => {
          }),
          catchError((error) => {
            throw error;
          })
        ).subscribe((resp: any) => {
          if (resp.Data.length > 0) {
            this.recommendations = resp.Data;
            this.filteredNames = this.recommendations.map(data => data.Name);
          }
        });
      } else {
        this.filteredNames = [];
      }
    } else if (this.selectedOption === 'componente') {
      if (this.query.length > 2) {
        this._component.GetComponentByName(this.query).pipe(
          finalize(() => {
          }),
          catchError((error) => {
            throw error;
          })
        ).subscribe((resp: any) => {
          if (resp.Data.length > 0) {
            this.components = resp.Data;
            this.filteredNames = this.components.map(data => data.Name);
          }
        });
      } else {
        this.filteredNames = [];
      }
    }

  }

  search() {
    this._analyticsService.trackEvent("SEARCHBAR_BUTTON_PRESS", this.query, "USER_INTERACTION");
    if (this.query != '' && this.selectedOption != null) {
      //this.router.navigate(['/products', this.query, this.selectedOption]);

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/products', this.query, this.selectedOption]);
      });
      this.filteredNames = [];
      //  this.onSearch.emit({query: this.query, selectedOption: this.selectedOption});
    }
  }


  selectName(name: string) {
    this.query = name;
    this.filteredNames = [];
    this.searchFromRecom(name);
  }

  searchFromRecom(name: string) {
    this._analyticsService.trackEvent("SEARCHBAR_TYPEAHEAD_OPTION_PRESS", name, "USER_INTERACTION");
    this.query = name;

    let currentUrl = this.router.url;

    console.log(currentUrl);
    if (this.query != '' && this.selectedOption != null) {
      //  this.router.navigate(['/products', this.query, this.selectedOption]);

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/products', this.query, this.selectedOption]);
      });

    }
  }

  getPlaceholder(): string {
    return this.placeholders[this.selectedOption as keyof typeof this.placeholders];
  }
}
