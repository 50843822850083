import { Component, inject } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { ProductService } from 'src/app/services/product.service';
import { catchError, finalize } from 'rxjs';
import { ProductModel } from 'src/app/models/product-model';
import { CarouselComponent } from "../shared/carousel/carousel.component";
import { ProviderService } from 'src/app/services/provider.service';
import { ProviderModel } from 'src/app/models/provider-model';
import { NgClass, NgFor } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CountryModel } from 'src/app/models/country-model';
import { GlobalService } from 'src/app/services/global.service';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { LoginComponent } from '../login/login.component';
import { CarouselModule } from 'primeng/carousel';
import { LookupdataService } from 'src/app/services/lookupdata.service';

@Component({
  selector: 'app-discount-section',
  standalone: true,
  imports: [SearchBarComponent, CarouselComponent, NgFor, RouterLink, NgClass, SubscribeComponent, LoginComponent, CarouselModule],
  templateUrl: './discount-section.component.html',
  styleUrl: './discount-section.component.css'
})
export class DiscountSectionComponent {
  responsiveOptions: any[] | undefined;
  private readonly _productService = inject(ProductService);
  products!: ProductModel[];
  providers!: ProviderModel[];
  defaultCountry!: CountryModel | undefined;
  className: string = "";
  providerIdSelected: string = "";
  private _gobalService= inject(GlobalService);
  constructor(private providerService: ProviderService, private route: ActivatedRoute, private lookupdataService: LookupdataService) {

  }
  async ngOnInit() {
    var countryName = this.route.snapshot.paramMap.get("country") as string;
    this._gobalService.getDefaultCountry().subscribe(data => {
      this.defaultCountry = data;
      if (this.defaultCountry.Name != countryName) {
        if (countryName == "Perú") {
          this.providerService.GetByCountryId(7).pipe(
            finalize(() => {
            }),
            catchError((error) => {
              throw error;
            })
          ).subscribe((data) => {
            if (data.IsSuccess) {
              this.providers = data.Data;
              this.GetDiscountProducts("7");
              this.lookupdataService.GetCountryById(7).pipe(
                finalize(() => {
                }),
                catchError((error) => {
                  throw error;
                })
              ).subscribe((data) => {
                this._gobalService.setDefaultCountry(data.Data[0]);
              });
            }
          });
        }
      } else {
        if (this.defaultCountry?.Id) {
          this.Providers();
          this.GetDiscountProducts(this.defaultCountry.Id);
        }
      }
    });
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 5,
        numScroll: 5
      },
      {
        breakpoint: '991px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '767px',
        numVisible: 3,
        numScroll: 3
      }
    ];
  }


  GetDiscountProducts(countryId:string) {
    this._productService.GetDiscountProducts(countryId).pipe(
      finalize(() => {
      }),
      catchError((error) => {
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.products = data.Data.sort(() => Math.random() - 0.5);
        this.products = this.products.slice(0, 9);
        this.products = this.products.sort((a, b) => b.Discount - a.Discount);
      }
    });
  }

  GetRandomProducts() {
    this._productService.GetRandomProducts().pipe(
      finalize(() => {
      }),
      catchError((error) => {
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.products = data.Data;

      }
    });
  }


  KeyPressProvider(event: KeyboardEvent, ProviderID: string) {
    if (event.code === 'Space' || event.code === 'Enter') {
      this.ChangeProvider(ProviderID);
    }
}

  ChangeProvider(ProviderID: string) {
    if (this.providerIdSelected == ProviderID) {
      this.providerIdSelected = ""
      this.GetDiscountProducts(this.defaultCountry?.Id ?? '1');
    } else {
      this.providerIdSelected = ProviderID
      this._productService.GetRandomProductsByProviderId(ProviderID).pipe(
        finalize(() => {
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe((data) => {
        if (data.IsSuccess) {
          this.products = data.Data;
        }
      });
    }
  }


  Providers() {
    this.providerService.GetByCountryId( parseInt(this.defaultCountry?.Id!)).pipe(
      finalize(() => {
      }),
      catchError((error) => {
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.providers = data.Data;
      }
    });
  }
}
