<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <img src="../../../assets/ubico_agregar_farmacia.png" class="img-fluid mb-4">
            <h3 class="seccion-header">Agregar mi farmacia</h3>
            <p>Le gustaría que su farmacia aparezca en nuestra aplicaci&oacute;n?. Complete
                el siguiente formulario para permitir a los usuarios visualizar sus productos y promociones en
                nuestra plataforma. En breve nuestro equipo se pondr&aacute; en contacto con usted.</p>
        </div>
        <div class="col-lg-2"></div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <form [formGroup]="requestForm" (ngSubmit)="onSubmit();">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="boxborder">
                            <div class="content">
                                <div class="flypill"></div>
                                <div class="form-group mb-3">
                                    <label for="name">Nombre:</label>
                                    <input type="text" formControlName="name" class="form-control form-input-style"
                                        placeholder="Ingrese su nombre"
                                        [ngClass]="{'is-invalid': requestSubmitted && f['name'].errors }">
                                    <div *ngIf="requestSubmitted && f['name'].errors" class="invalid-feedback">
                                        <div *ngIf="f['name'].errors['required']">Nombre es requerido.</div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="managerName">Encargado:</label>
                                    <input type="text" formControlName="managerName"
                                        class="form-control  form-input-style"
                                        placeholder="Ingrege el Nombre del encargado"
                                        [ngClass]="{'is-invalid': requestSubmitted && f['managerName'].errors }">
                                    <div *ngIf="requestSubmitted && f['managerName'].errors" class="invalid-feedback">
                                        <div *ngIf="f['managerName'].errors['required']">Nombre del encargado es
                                            requerido.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="emailAddress">Correo electr&oacute;nico:</label>
                                    <input type="text" formControlName="emailAddress"
                                        class="form-control  form-input-style" placeholder="Ingrese su correo"
                                        [ngClass]="{'is-invalid': requestSubmitted && f['emailAddress'].errors }">
                                    <small class="form-text text-muted">Nunca compartimos su correo electr&oacute;nico
                                        con terceros.</small>
                                    <div *ngIf="requestSubmitted && f['emailAddress'].errors" class="invalid-feedback">
                                        <div *ngIf="f['emailAddress'].errors['required']">Correo electr&oacute;nico
                                            es
                                            requerido.</div>
                                    </div>
                                    <div *ngIf="requestSubmitted && f['emailAddress'].errors" class="invalid-feedback">
                                        <div *ngIf="f['emailAddress'].errors['email']">Formato de correo
                                            inv&aacute;lido.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="phoneNumber">Numero de tel&eacute;fono:</label>
                                    <input type="text" formControlName="phoneNumber"
                                        class="form-control  form-input-style" aria-describedby="emailHelp"
                                        placeholder="Ingrese su numero telef&oacute;nico" mask="(000) 0000-0000"
                                        [ngClass]="{'is-invalid': requestSubmitted && f['phoneNumber'].errors }">
                                    <small class="form-text text-muted">Nunca compartimos su tel&eacute;fono con
                                        terceros.
                                        (Formato
                                        (XXX) 9999-999). </small>
                                    <div *ngIf="requestSubmitted && f['phoneNumber'].errors" class="invalid-feedback">
                                        <div *ngIf="f['phoneNumber']errors['required']">El tel&eacute;fono es
                                            requerido.
                                        </div>
                                        <div *ngIf="f['phoneNumber'].errors['minlength']">Formato de
                                            tel&eacute;fono
                                            inv&aacute;lido.
                                        </div>
                                    </div>
                                </div>
                                <div style="display: none;">
                                    <input type="text" formControlName="imageURL" class="form-control"
                                        placeholder="Logo de la farmacia">
                                </div>
                                <div class="form-group mb-3">
                                    <label for="message">Mensaje:</label>
                                    <textarea class="form-control form-input-style" formControlName="message" rows="3"
                                        placeholder="Escriba su comentario o consulta"
                                        [ngClass]="{'is-invalid': requestSubmitted && f['message'].errors }"></textarea>
                                    <div *ngIf="requestSubmitted && f['message'].errors" class="invalid-feedback">
                                        <div *ngIf="f['message'].errors['required']">El mensaje es requerido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center button-container d-none d-lg-block">
                                <button type="submit" class="button-variation"><i class="fa fa-envelope">
                                        </i> Enviar</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <div class="new-logo-container">
                            <div class="form-group">
                                <label for="imageURL">Logo de su farmacia:</label><br>
                                <img [src]="imageFile" class="rounded mx-auto d-block new-logo-img">
                            </div>
                            <div class="form-group">
                                <input type="file" #file id="inputFile" name="inputFile" accept='image/*'
                                    style="display:none;" (change)="onFileChange(file.files)" />
                                <div class="text-center">
                                    <input type="text" placeholder="Seleccione la imagen" class="form-control imageURL"
                                        formControlName="imageURL" style="display:none;">
                                    <button type="button" label="Seleccionar" class="cta d-inline"
                                        (click)="onSearchImage();">Seleccionar</button>&nbsp;
                                    <button type="button" label="Eliminar" class="cta d-inline"
                                        (click)="onClearImage();">Eliminar</button>
                                </div>
                                <div *ngIf="imageMessage" class="text-danger">
                                    {{imageMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-lg-none d-block">
                    <div class="col-sm-12 mt-4 mb-3" style="text-align: center">
                        <button type="submit" class="button-variation d-inline"><i class="fa fa-envelope">
                        </i> Enviar</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-2"></div>
    </div>
</div>