import { Component, inject } from '@angular/core';
import { LookupdataService } from './services/lookupdata.service';
import { catchError, finalize } from 'rxjs';
import { CountryModel } from './models/country-model';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalService } from './services/global.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Ubico';
  countries!: CountryModel[];
  selectedCountry!: string | undefined;
  defaultCountry!: CountryModel | undefined;
  className: string = "";
  private _gobalService = inject(GlobalService);
  private readonly _analyticsService = inject(AnalyticsService);
  constructor(private lookupdataService: LookupdataService, private router: Router) { Carousel.prototype.onTouchMove = () => { };}

  ngOnInit() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._analyticsService.trackRoute(event.urlAfterRedirects);
      }
    }); // analitycs track routes

    this.defaultCountry = new CountryModel();
    this.GetCountries();
  }

  ChangeCountry(selectedCountry: any) {
    this.defaultCountry = this.countries.find(x => x.Id == selectedCountry)
    this._gobalService.setDefaultCountry(this.defaultCountry!);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/search', this.defaultCountry?.Name]);
    });
  }

  GetCountries() {
    this.lookupdataService.GetCountries().pipe(
      finalize(() => {
      }),
      catchError((error) => {
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.countries = data.Data;
        if (this.defaultCountry?.Name === undefined) {
          this.defaultCountry = this.countries.find(x => x.IsDefaultCountry == true)
          this._gobalService.setDefaultCountry(this.defaultCountry!);
          this.router.navigate(['/search', this.defaultCountry?.Name]);
        }
      }
    });
  }
}
