export class BaseModel {
    public Id!: string;
    public ClientId!: string;
    public Detail!: string;
    public IsActive!: boolean;
    public CreatedBy!: string;
    public CreatedDate!: Date;
    public ModifiedBy?: string;
    public ModifiedDate?: Date;
    public DeletedBy?: string;
    public DeletedDate?: Date;
}
