<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-lg-8">
            <h2 class="seccion-header">Bienvenidos a nuestro Blog</h2>
            <p>En nuestro blog encontrarás un espacio dedicado a la salud y el bienestar, donde profesionales de la
                medicina y especialistas de diversas áreas comparten información basada en evidencia científica. Aquí
                exploramos temas de interés médico, consejos de salud, noticias y avances en tratamientos, explicados de
                forma clara y accesible para todos.</p>
            <ul class="blog-list">
                <!-- todo hacer que esto venga de algun CMS o parecido , talvez notion, de ser possible con filtros y paginacion -->
                <a href="" [routerLink]="['/newsletter',1]" class="blog-item" title="Cuanto dura la conjuntivitis?">
                    <img src="../../../assets/newsletter/blog_01_01_conjuntivitis.jpg" class="img-fluid"
                        title="Cuanto dura la conjuntivitis?">
                </a>
                <a href="" [routerLink]="['/newsletter',2]" class="blog-item" title="Resfriados?">
                    <img src="../../../assets/newsletter/blog_02_01_resfriado.jpg" class="img-fluid"
                        title="Resfriados?">
                </a>
                <a href="" [routerLink]="['/newsletter',3]" class="blog-item"
                    title="Lantus vs. Ozempic: 6 diferencias a tener en cuenta">
                    <img src="../../../assets/newsletter/blog_03_01_lantusVSozempic.jpg" class="img-fluid"
                        title="Lantus vs. Ozempic: 6 diferencias a tener en cuenta">
                </a>
                <a href="" [routerLink]="['/newsletter',4]" class="blog-item"
                    title="7 formas de prevenir un resfriado cuando lo sientes venir">
                    <img src="../../../assets/newsletter/blog_04_01_prevenirResfriado.jpg" class="img-fluid"
                        title="7 formas de prevenir un resfriado cuando lo sientes venir">
                </a>
                <a href="" [routerLink]="['/newsletter',5]" class="blog-item"
                    title="Las 6 mejores cosas para beber cuando tienes diarrea">
                    <img src="../../../assets/newsletter/blog_05_01_diarrea.jpg" class="img-fluid"
                        title="Las 6 mejores cosas para beber cuando tienes diarrea">
                </a>
                <a href="" [routerLink]="['/newsletter',5]" class="blog-item"
                    title="L¿La vacuna contra la gripe puede enfermarte?">
                    <img src="../../../assets/newsletter/blog_06_01_vacunacontragripe.jpg" class="img-fluid"
                        title="¿La vacuna contra la gripe puede enfermarte?">
                </a>
            </ul>

        </div>
    </div>