import { Component } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { RouterModule } from '@angular/router';
import { LookupdataService } from 'src/app/services/lookupdata.service';
import { catchError, finalize } from 'rxjs';
import { CountryModel } from 'src/app/models/country-model';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, NgFor } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SearchBarComponent, RouterModule,FormsModule, NgFor],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  countries!: CountryModel[];
  date!: Date;
  constructor(private lookupdataService: LookupdataService) { }

  ngOnInit() {
    this.GetCountries();
  }

  GetCountries() {
    this.lookupdataService.GetCountries().pipe(
      finalize(() => {
      }),
      catchError((error) => {
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.countries = data.Data;
      }
    });
  }
}
