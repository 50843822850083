import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResponseModel } from '../models/base-response-model';
import { environment } from 'src/environments/environment';
import { ComponentModel } from '../models/component-model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  private serviceURI!: string;
  constructor(private httpClient: HttpClient) {
    this.serviceURI = "https://ubicolatam.com";
    this.serviceURI = environment.serviceURI;
  }

  GetComponentByName( name:string): Observable<BaseResponseModel<ComponentModel>> {
    return this.httpClient.get<BaseResponseModel<ComponentModel>>(this.serviceURI + `/api/Component/GetComponentByName?name=${name}`,httpOptions);
  }

}
