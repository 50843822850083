import { Injectable } from '@angular/core';
import { CountryModel } from '../models/country-model';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private query: string = '';
  private defaultCountry = new BehaviorSubject<CountryModel>(new CountryModel);
  constructor() { }
  setDefaultCountry(country:CountryModel): void {
    this.defaultCountry.next(country);
  }
  getDefaultCountry(): Observable<CountryModel> {
    return this.defaultCountry.asObservable();
  }
}