import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { catchError, finalize } from 'rxjs/operators';
import { SubscribeModel } from 'src/app/models/subscribe-model';
import { SubscribeService } from 'src/app/services/subscribe.service';


@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule, NgIf, ToastModule],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.css'
})
export class SubscribeComponent implements OnInit {
  form!: FormGroup;
  formSubmitted: boolean = false;
  subscriptionSubmitted: boolean = false;
  loading: boolean = false;


  @Input() ClientId: string = '00000000-0000-0000-0000-000000000000';
  @Input() ApplicationId: number = 0;
  @Output() onClick = new EventEmitter<boolean>();

  constructor(private contactService: SubscribeService, private messageService: MessageService) { }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.form = new FormGroup({
      emailAddress: new FormControl(null, [Validators.required,Validators.email])
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    let model = new SubscribeModel();
    model.Id = '00000000-0000-0000-0000-000000000000';
    model.ClientId = this.ClientId;
    model.EmailAddress = this.form.value.emailAddress;
    model.CreatedBy = '00000000-0000-0000-0000-000000000000';
    model.IsActive = true;
    model.CreatedDate = new Date();
    this.contactService.Create(model).pipe(
      finalize(() => {
        this.formSubmitted = false;
        this.loading = false;
      }),
      catchError((error) => {
        this.onClick.emit(false);
        this.messageService.add({ key: 'c', sticky: true, severity: 'error', summary: 'Proceso exitoso', detail: 'Se ha producido un error!' });
 
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'success', summary: 'Proceso exitoso', detail: 'Se ha subscrito con exito!' });
        this.form.reset();
      } else {
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'error', summary: 'Proceso exitoso', detail: 'Se ha producido un error!' });
      }
    });
  }
  onReject() {

  }
  onConfirm() {
    this.messageService.clear();
  }
  get f() { return this.form.controls; }
}