import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {  ToastModule } from 'primeng/toast';
import { catchError, finalize } from 'rxjs/operators';
import { ContactModel } from 'src/app/models/contact-model';
import { ContactService } from 'src/app/services/contact.service';


@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule, NgIf,ToastModule],
  templateUrl: './contact-page.component.html',
  styleUrl: './contact-page.component.css'
})
export class ContactPageComponent implements OnInit {
  contactForm!: FormGroup;
  contactSubmitted: boolean = false;
  subscriptionSubmitted: boolean = false;
  loading: boolean = false;


  @Input() ClientId: string = '00000000-0000-0000-0000-000000000000';
  @Input() ApplicationId: number = 0;
  @Output() onClick = new EventEmitter<boolean>();

  constructor(private contactService: ContactService, private messageService: MessageService) { }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.contactForm = new FormGroup({
      contactName: new FormControl(null, [Validators.required]),
      contactEmail: new FormControl(null, [Validators.required, Validators.email]),
      contactPhone: new FormControl("(506)", [ Validators.minLength(11)]),
      contactMessage: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    this.contactSubmitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.loading = true;
    let contactDto = new ContactModel();
    contactDto.Id = '0';
    contactDto.ClientId = this.ClientId;
    contactDto.Name = this.contactForm.value.contactName;
    contactDto.EmailAddress = this.contactForm.value.contactEmail;
    contactDto.PhoneNumber = this.contactForm.value.contactPhone;
    contactDto.Message = this.contactForm.value.contactMessage;
    contactDto.CreatedBy = '00000000-0000-0000-0000-000000000000';
    contactDto.CreatedDate = new Date();
    this.contactService.Create(contactDto).pipe(
      finalize(() => {
        this.contactSubmitted = false;
        this.loading = false;
      }),
      catchError((error) => {
        this.onClick.emit(false);
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.messageService.clear();
        this.messageService.add({key: 'c', sticky: true, severity:'success', summary:'Proceso exitoso', detail:'Su comentario se ha enviado con exito!'});
        this.contactForm.reset();
      } else {
        this.messageService.clear();
        this.messageService.add({key: 'c', sticky: true, severity:'error', summary:'Proceso exitoso', detail:'Se ha producido un error!'});    
      }
    });
  }
  onReject(){

  }
  onConfirm(){
    this.messageService.clear();
  }
  get f() { return this.contactForm.controls; }
}