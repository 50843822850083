<p-carousel class="products-carousel" [value]="products" [numVisible]="5" [numScroll]="2" [circular]="false"
    [responsiveOptions]="responsiveOptions" autoplayInterval="6000">
    <ng-template let-product pTemplate="item">
        <div class="card text-center">
            <div class="card-header">
                <div *ngIf="product.PriceWithDiscount>0" class="discount">-{{product.Discount}}%</div>
            </div>
            <div class="card-body">
                <div class="product-image">
                    <img [src]="product.ImageURL" title="{{ product.Name }}" width="100" height="100">
                </div>
                <span class="card-title textmax">{{product.Name}}</span>
                <p class="card-subtitle">{{product.ProviderName}}</p>
                <p *ngIf="product.PriceWithDiscount>0; else NoDiscount" class="price">
                    <span class="price--old">{{product.Price | currency: this.country.CurrencyCode }}</span>
                    {{product.PriceWithDiscount | currency: this.country.CurrencyCode }}
                </p>
                <ng-template #NoDiscount>
                    <p class="price">{{product.Price | currency: this.country.CurrencyCode }}</p>
                </ng-template>
                <a class="cta" href="{{product.ProductURL}}" target="_blank"
                (click)="productSelected(product.Name,product.ProviderName,product.ProductURL)">Ir a Farmacia<i
                    class="fa fa-external-link external-link" aria-hidden="true"></i></a>
            </div>
        </div>
    </ng-template>
</p-carousel>