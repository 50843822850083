<div class="container-fluid contact">
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <img src="../../../assets/ubico_contactenos.png" class="img-fluid mb-4">
            <h3 class="seccion-header">Cont&aacute;ctenos</h3>
            <h5>Su opini&oacute;n es importante</h5>
            <p>Nos encancataría saber sobre usted. Ay&uacute;danos a mejorar brind&aacute;ndonos su opini&oacute;n,
                comentarios o env&iacute;enos sus consultas
                y en breve nuestro equipo se pondr&aacute; en contacto con usted.</p>
            <div class="row">
                <div class="col-md-6">
                    <div class="boxborder">
                        <div class="content">
                            <div class="flypill"></div>
                            <form [formGroup]="contactForm" (ngSubmit)="onSubmit();" style="padding: 5px;">
                                <div class="form-group mb-3">
                                    <label for="contactName">Nombre:</label>
                                    <input type="text" formControlName="contactName" class="form-control form-input-style"
                                        placeholder="Ingrese su nombre"
                                        [ngClass]="{'is-invalid': contactSubmitted && f['contactName'].errors }">
                                    <div *ngIf="contactSubmitted && f['contactName'].errors" class="invalid-feedback">
                                        <div *ngIf="f['contactName'].errors['required']">Nombre es requerido.</div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="contactEmail">Correo electr&oacute;nico:</label>
                                    <input type="text" formControlName="contactEmail" class="form-control form-input-style"
                                        placeholder="Ingrese su correo"
                                        [ngClass]="{'is-invalid': contactSubmitted && f['contactEmail'].errors }">
                                    <small class="form-text text-muted">Nunca compartimos su correo electr&oacute;nico
                                        con terceros.</small>
                                    <div *ngIf="contactSubmitted && f['contactEmail'].errors" class="invalid-feedback">
                                        <div *ngIf="f['contactEmail'].errors['required']">Correo electr&oacute;nico
                                            es
                                            requerido.</div>
                                    </div>
                                    <div *ngIf="contactSubmitted && f['contactEmail'].errors" class="invalid-feedback">
                                        <div *ngIf="f['contactEmail'].errors['email']">Formato de correo
                                            inv&aacute;lido.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="contactPhone">Numero de tel&eacute;fono:</label>
                                    <input type="text" formControlName="contactPhone" class="form-control form-input-style"
                                        aria-describedby="emailHelp" placeholder="Ingrese su numero telef&oacute;nico"
                                        mask="(000) 0000-0000"
                                        [ngClass]="{'is-invalid': contactSubmitted && f['contactPhone'].errors }">
                                    <small class="form-text text-muted">Nunca compartimos su tel&eacute;fono con
                                        terceros.
                                        (Formato
                                        (XXX) 9999-999). </small>
                                    <div *ngIf="contactSubmitted && f['contactPhone'].errors" class="invalid-feedback">
                                        <div *ngIf="f['contactPhone']errors['required']">El tel&eacute;fono es
                                            requerido.
                                        </div>
                                        <div *ngIf="f['contactPhone'].errors['minlength']">Formato de
                                            tel&eacute;fono
                                            inv&aacute;lido.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="message">Mensaje:</label>
                                    <textarea class="form-control form-input-style" formControlName="contactMessage" rows="3"
                                        placeholder="Escriba su comentario o consulta"
                                        [ngClass]="{'is-invalid': contactSubmitted && f['contactMessage'].errors }"></textarea>
                                    <div *ngIf="contactSubmitted && f['contactMessage'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f['contactMessage'].errors['required']">El mensaje es requerido.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center button-container">
                                    <button type="submit" class="button-variation"><i
                                            class="fa fa-envelope">
                                            </i>Enviar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-md-block">
                    <div class="contact-address">
                        <div class="mb-2">
                            <i class="fa fa-home me-3"></i>
                            <b>Direcci&oacute;n:</b>
                            <p class="form-control contact-address-box">San Jos&eacute;, San Jos&eacute;, Costa
                                Rica</p>
                        </div>
                        <div class="mb-2"><i class="fa fa-phone me-3"></i>
                            <b>Tel&eacute;fono:</b>
                            <p class="form-control contact-address-box"> + (506) 8603 6047</p>
                        </div>
                        <div class="mb-2"><i class="fa fa-envelope me-3"></i> <b>Email:</b>
                            <p class="form-control contact-address-box"> contact&#64;synergytechnology.net</p>
                        </div>
                        <div class="mb-2"><i class="fa fa-globe me-3"></i> <b>Website:</b>
                            <p class="form-control contact-address-box">synergytechnology.net</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2"></div>
    </div>
</div>
<p-toast position="top-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 text-center">
                    <button type="button" (click)="onConfirm()" class="btn btn-secondary"
                        style="margin-bottom: 25px;">Aceptar</button>
                </div>
                <!-- <div class="col-6">
                    <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div> -->
            </div>
        </div>
    </ng-template>
</p-toast>