import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResponseModel } from '../models/base-response-model';
import { RecommendationModel } from '../models/recommendation-model';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {
  private serviceURI!: string;
  constructor(private httpClient: HttpClient) {

    this.serviceURI = environment.serviceURI;
  }

  GetRecommendationByName( name:string): Observable<BaseResponseModel<RecommendationModel>> {
    return this.httpClient.get<BaseResponseModel<RecommendationModel>>(this.serviceURI + `/api/Recommendation/GetRecommendationByName?name=${name}`,httpOptions);
  }

}
