<div class="bg_latam">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <img class="img-fluid" title="Ubico - Comparador de precios" width="700px"
                    src="../../../assets/iconos pagina web - ubico_logo horizontal - pagina de entrada.png">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <h3 class="title">Seleccione el pa&iacute;s en el que busca:</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="country-grid">
                    <div *ngFor="let country of countries; index as i; first as isFirst" class="country-card fade-in-up"
                        style="animation-delay: 0.1s;">
                        <a [routerLink]="['/search',country.Name]">
                            <img src="../../../assets/{{country.FlagImageURL}}" title="Bandera de {{country.Name}}"
                                class="country-flag">
                            <h3 class="country-name">
                                {{country.Name}}</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <h3 class="information"><span>Encuentre sus medicamentos al mejor precio,
                        cerca de usted</span></h3>
            </div>
        </div>
    </div>
</div>