import { BaseModel } from "./base-model";

export class CountryModel extends BaseModel {
    public Name!: string;
    public Abbreviation!: string;
    public ZipCode!: number;
    public FlagImageURL!: string;
    public MapImageURL!: string;
    public IsDefaultCountry!: boolean;
    public  CurrencyCode!: string;
    public  CurrencyName !: string;
}