import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './newsletter-page.component.html',
  styleUrl: './newsletter-page.component.css'
})
export class NewsletterPageComponent {
  private readonly _analyticsService = inject(AnalyticsService);
  constructor(private route: ActivatedRoute) { }
  newsletterid: number = 1;

  titles = {
    1: "Cuanto dura la conjuntivitis?",
    2: "Resfriados?",
    3: "Lantus vs. Ozempic: 6 diferencias a tener en cuenta",
    4: "7 formas de prevenir un resfriado cuando lo sientes venir",
    5: "Las 6 mejores cosas para beber cuando tienes diarrea",
    6: "¿La vacuna contra la gripe puede enfermarte?"
  };

  images = {
    1: "../../../assets/newsletter/blog_01_01_conjuntivitis.jpg",
    2: "../../../assets/newsletter/blog_02_01_resfriado.jpg",
    3: "../../../assets/newsletter/blog_03_01_lantusVSozempic.jpg",
    4: "../../../assets/newsletter/blog_04_01_prevenirResfriado.jpg",
    5: "../../../assets/newsletter/blog_05_01_diarrea.jpg",
    6: "../../../assets/newsletter/blog_06_01_vacunacontragripe.jpg",
  };

  ngOnInit() {
    this.newsletterid = Number.parseInt(this.route.snapshot.paramMap.get("id") as string);
    this._analyticsService.trackEvent("NEWSLETTER_VIEW", "" + this.newsletterid, "USER_NAVIGATION");
  }

  getTitle(): string {
    return this.titles[this.newsletterid as keyof typeof this.titles];
  }
  getImage(): string {
    return this.images[this.newsletterid as keyof typeof this.images];
  }
}
