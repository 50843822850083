<section class="newsletter">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="content">
                    <form [formGroup]="form" (ngSubmit)="onSubmit();">
                        <p class="text-muted">Suscr&iacute;bete a nuestro Newsletter:</p>
                        <div class="input-group">
                            <!-- <span class="input-group-btn">
                                <div class="btn" style="cursor:default;"><i class=" fa fa-envelope"></i></div>                                
                            </span> -->
                            <input type="email" class="form-control" placeholder="Ingrese su correo"
                                formControlName="emailAddress"
                                [ngClass]="{'is-invalid': formSubmitted && f['emailAddress'].errors }">
                            <span class="input-group-btn">
                                <button class="btn" type="submit">Suscr&iacute;bete</button>
                            </span>
                            <div *ngIf="formSubmitted && f['emailAddress'].errors" class="invalid-feedback">
                                <div *ngIf="f['emailAddress'].errors['required']">Email es requerido.</div>
                            </div>
                            <div *ngIf="formSubmitted && f['emailAddress'].errors" class="invalid-feedback">
                                <div *ngIf="f['emailAddress'].errors['email']">Formato de correo
                                    inv&aacute;lido.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<p-toast position="top-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 text-center">
                    <button type="button" (click)="onConfirm()" class="btn btn-secondary"
                        style="margin-bottom: 25px;">Aceptar</button>
                </div>
                <!-- <div class="col-6">
                    <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div> -->
            </div>
        </div>
    </ng-template>
</p-toast>