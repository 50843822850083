<div class="container-fluid faq">
    <div class="row">
        <div class="col-lg-2">

        </div>
        <div class="col-lg-8">
            <img src="../../../assets/faq/ubico_faq_banner.jpg" alt="Preguntas Frecuentes" class="img-fluid mb-3">

            <h4>¿Qu&eacute; es Ubico?</h4>
            <p>Permite a los usuarios comparar precios de medicamentos, suplementos y productos de salud en
                Costa Rica.
                Nuestro objetivo es proporcionar informaci&oacute;n clara y precisa para ayudar a los
                consumidores a
                tomar decisiones informadas sobre sus compras de salud. </p>


            <h4>¿C&oacute;mo funciona Ubico?</h4>
            <p>Ubico centraliza datos de precios de diversas fuentes, permitiendo a los usuarios buscar y
                comparar productos por nombre, categor&iacute;a o ubicaci&oacute;n. La plataforma ofrece filtros
                personalizados para facilitar la b&uacute;squeda y proporciona visualizaciones de tendencias de
                precios.
            </p>


            <img src="../../../assets/faq/ubico_faq_ahorro.jpg" alt="ahorro" class="img-fluid img--right">
            <h4>¿Por qu&eacute; deber&iacute;a usar Ubico?</h4>
            <p>Usar Ubico te permite acceder a informaci&oacute;n actualizada sobre precios y disponibilidad de
                productos de
                salud,
                lo que te ayuda a encontrar las mejores ofertas y tomar decisiones de compra m&aacute;s
                informadas.
                Adem&aacute;s,
                contribuyes a fomentar la competencia y la transparencia en el mercado farmac&eacute;utico.</p>



            <h4>¿Es gratuito el uso de Ubico?</h4>
            <p>S&iacute;, el acceso a la plataforma Ubico es completamente gratuito para los usuarios. Nuestro
                objetivo
                es
                democratizar el acceso a la informaci&oacute;n de precios de medicamentos en la regi&oacute;n.
            </p>


            <h4>¿Qu&eacute; tipo de productos puedo buscar en Ubico?</h4>
            <p>En Ubico puedes buscar una amplia variedad de productos, incluidos medicamentos de
                prescripci&oacute;n y
                de
                venta libre, suplementos nutricionales, fitof&aacute;rmacos y productos cosm&eacute;ticos y
                dermatol&oacute;gicos.</p>


            <img src="../../../assets/faq/ubico_faq_tecnologia.jpg" alt="tecnologia" class="img-fluid img--left">
            <h4>¿C&oacute;mo se asegura Ubico de que los datos sean precisos y actualizados?</h4>
            <p>Ubico integra datos de m&uacute;ltiples fuentes confiables, incluidas APIs de comercios
                electr&oacute;nicos y bases
                de datos gubernamentales. Adem&aacute;s, utilizamos algoritmos de detecci&oacute;n de
                anomal&iacute;as y
                validaci&oacute;n cruzada
                para
                asegurar la precisi&oacute;n de la informaci&oacute;n.</p>


            <h4>¿En qu&eacute; pa&iacute;ses est&aacute; disponible Ubico?</h4>
            <p>Actualmente, Ubico est&aacute; disponible en seis pa&iacute;ses de Centroam&eacute;rica:
                Guatemala, El
                Salvador,
                Honduras, Nicaragua, Costa Rica y Panam&aacute;.</p>
            <img src="../../../assets/faq/ubico_faq_america.jpg" alt="america" class="img-fluid img--right">

            <h4>¿C&oacute;mo puedo reportar un error o discrepancia en los precios?</h4>
            <p>Si encuentras un error o discrepancia en los precios, puedes reportarlo directamente a
                trav&eacute;s de nuestra plataforma. Contamos con un sistema de retroalimentaci&oacute;n que nos
                ayuda a
                mantener
                la precisi&oacute;n y confiabilidad de nuestros datos.</p>


            <h4>¿Ubico vende directamente los productos?</h4>
            <p>No, Ubico no vende productos directamente. Somos una plataforma informativa que proporciona datos
                comparativos sobre precios de medicamentos y productos de salud.</p>


            <h4>¿C&oacute;mo protege Ubico mi privacidad?</h4>
            <p>Ubico implementa protocolos de seguridad robustos, incluyendo encriptaci&oacute;n de datos y
                autenticaci&oacute;n
                segura,
                para proteger la informaci&oacute;n personal de nuestros usuarios. Cumplimos con las normativas
                de
                protecci&oacute;n
                de datos aplicables en la regi&oacute;n.</p>

            <img src="../../../assets/about/ubico_about_colors.png" class="img-fluid mt-3 mb-5">
        </div>
        <div class="col-lg-2">

        </div>
    </div>
</div>