import { BaseModel } from "./base-model";

export class ContactModel extends BaseModel {
    public Name!: string;
    public EmailAddress!: string;
    public PhoneNumber!: string;
    public Message!: string;
    public StatusId!: number;
    public AnsweredDate!: Date;
    public AnsweredBy!: string;
    public AnswerMessage!: string;
}