import { Component, inject } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { ProductService } from 'src/app/services/product.service';
import { CarouselComponent } from "../shared/carousel/carousel.component";
import { ProviderService } from 'src/app/services/provider.service';
import { NgClass, NgFor } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CountryModel } from 'src/app/models/country-model';
import { GlobalService } from 'src/app/services/global.service';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { LoginComponent } from '../login/login.component';
import { DiscountSectionComponent } from '../discount-section/discount-section.component';
import { catchError, finalize } from 'rxjs';
import { LookupdataService } from 'src/app/services/lookupdata.service';

declare var $: any;

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [SearchBarComponent, CarouselComponent, NgFor, RouterLink, NgClass, SubscribeComponent, LoginComponent, DiscountSectionComponent],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent {
  private readonly _productService = inject(ProductService);

  defaultCountry!: CountryModel | undefined;
  className: string = "";
  countryID: number = 1;
  constructor(private providerService: ProviderService, private route: ActivatedRoute, private _gobalService: GlobalService, private lookupdataService: LookupdataService) {
  }

  ngOnInit() {
    var countryName = this.route.snapshot.paramMap.get("country") as string;
    this._gobalService.getDefaultCountry().subscribe(data => {

      this.defaultCountry = data;
      if (this.defaultCountry.Name != countryName) {
        if (countryName == "Perú") {
          this.countryID = 7;
        }else{
          this.countryID = 1;
        }


          this.lookupdataService.GetCountryById( this.countryID).pipe(
            finalize(() => {
            }),
            catchError((error) => {
              console.log(-1);
              throw error;
            })
          ).subscribe((data) => {
            this._gobalService.setDefaultCountry(data.Data[0]);
            this.className = "bg_" + data.Data[0].Name.replace(" ", "") + "C";
          });
        
      } else {
        this.className = "bg_" + this.defaultCountry.Name.replace(" ", "") + "C";
      }
    });
  }
}
