<div class="container-fullwidth">
    <div class="row">
        <div class="col-lg-12 mb-4 banner-container">
            <img src="{{getImage()}}" class="img-fluid" title="{{getTitle()}}">
        </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-lg-8 mb-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="" [routerLink]="['/']">Inicio</a></li>
                    <li class="breadcrumb-item"><a href="" [routerLink]="['/blog']">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{getTitle()}}</li>
                </ol>
            </nav>
        </div>

    </div>
    <!--  todo hacer que esto venga de algun CMS o notion o parecido  -->
    <div class="row" *ngIf="1==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>
                ¿Cu&aacute;nto dura la conjuntivitis?</h1>
            <div>
                <h3>Puntos clave:</h3>
                <ul>
                    <li>La conjuntivitis es una inflamaci&oacute;n de la capa externa del ojo. Es causada por algunas
                        cosas diferentes, incluidas alergias e infecciones.</li>
                    <li>La conjuntivitis puede durar desde unos pocos d&iacute;as hasta unas pocas semanas. La cantidad
                        de tiempo que tarda en desaparecer depende principalmente de la causa.</li>
                    <li>Si bien la conjuntivitis suele desaparecer por s&iacute; sola, en algunos casos es posible que
                        deba consultar a un profesional de la salud. Y hay algunas maneras de saber cu&aacute;ndo ese es
                        el caso.</li>
                </ul>
                <p>La conjuntivitis, m&aacute;s com&uacute;nmente conocida como conjuntivitis, es una inflamaci&oacute;n
                    de la capa m&aacute;s externa del ojo. La mayor&iacute;a de las personas est&aacute;n familiarizadas
                    con la forma en que la parte blanca del ojo se vuelve rosada o roja. Y puede ser molesto y bastante
                    inc&oacute;modo.
                </p>
                <p>Dado que la mayor&iacute;a de las personas experimentan una mezcla de picaz&oacute;n, ardor y costras
                    en el ojo, es com&uacute;n preguntarse qu&eacute; tan r&aacute;pido pasar&aacute; la conjuntivitis.
                    A continuaci&oacute;n, revisaremos las causas comunes de la afecci&oacute;n y c&oacute;mo esto
                    afecta la duraci&oacute;n de sus s&iacute;ntomas.</p>
            </div>

            <h3>Viral</h3>
            <p>La conjuntivitis causada por un virus suele desaparecer en 1 o 2 semanas. Pero puede tardar hasta 3
                semanas o m&aacute;s.</p>
            <h3>Bacteriano</h3>
            <p>Incluso sin tratamiento, la conjuntivitis bacteriana leve puede resolverse por s&iacute; sola en una
                semana. Los casos m&aacute;s graves pueden tardar hasta 2 semanas.</p>
            <h3>Al&eacute;rgico</h3>
            <p>La duraci&oacute;n de la conjuntivitis al&uacute;rgica depende del tiempo que la persona est&uacute;
                expuesta al al&uacute;rgeno. Una vez que se elimina el al&uacute;rgeno, los s&iacute;ntomas pueden
                desaparecer en 24 horas. Sin embargo, puede ser dif&iacute;cil identificar al culpable, y a&uacute;n
                m&aacute;s dif&iacute;cil eliminarlo.</p>
            <p>Por ejemplo, el polen estacional puede ser dif&iacute;cil de evitar. Y si eres intensamente
                al&uacute;rgico a la caspa
                de
                las
                mascotas, puede significar tener que reubicar a una mascota o recibir inyecciones de
                desensibilizaci&oacute;n
                para
                la
                alergia.
                Relacionado con otras enfermedades.
            <p>Si la conjuntivitis es parte de una enfermedad mayor, el curso del tiempo ser&aacute; variable
                dependiendo de la
                enfermedad
                subyacente. Y mejorar&aacute; una vez que se trate la afecci&oacute;n subyacente.</p>
            <img src="../../../assets/newsletter/blog_01_02_conjuntivitis.jpg" class="img-fluid" title="tipos">
            <h3>¿Cu&aacute;nto tiempo es contagiosa la conjuntivitis?</h3>
            <p>La conjuntivitis infecciosa puede ser muy contagiosa, especialmente si es viral. Una regla general es: si
                tiene s&iacute;ntomas, puede transmitir la conjuntivitis a otras personas. Esto significa que
                podr&iacute;a ser contagioso hasta por 2 a 3 semanas. La conjuntivitis al&uacute;rgica no es contagiosa.
            </p>
            <h3>¿C&oacute;mo se propaga la conjuntivitis?</h3>
            <p>La conjuntivitis infecciosa se transmite por contacto directo. Cuando te tocas el ojo, el virus o la
                bacteria entran en tus dedos. Y luego puede propagarse a otras personas a trav&uacute;s del contacto
                f&iacute;sico o superficies compartidas. Hay varias cosas que puede hacer para minimizar la
                propagaci&oacute;n de estos g&uacute;rmenes:</p>
            <ul>
                <li>L&aacute;vate las manos.</li>
                <li>Evite tocarse los ojos.</li>
                <li>Deje de usar lentes de contacto.</li>
                <li>No compartas toallas, pa&ntilde;uelos, pa&ntilde;uelos desechables, cosm&uacute;ticos, alimentos ni
                    bebidas.</li>
                <li>No nades en una piscina.</li>
                <li>Qu&eacute;dese en casa y no vaya a la escuela o al trabajo hasta que los s&iacute;ntomas
                    desaparezcan. </li>
            </ul>
            <h3>¿Qu&eacute; causa la conjuntivitis?</h3>
            <p> Hay muchas cosas que pueden causar conjuntivitis. Por lo tanto, es &uacute;til pensar en las causas en
                dos grandes categor&iacute;as:</p>
            <ul>
                <li>Infeccioso</li>
                <li>No infeccioso (inflamatorio)</li>
                <li>Infeccioso</li>
            </ul>
            <p>La causa m&aacute;s com&uacute;n de la conjuntivitis es una infecci&oacute;n. La infecci&oacute;n puede
                ser causada por:</p>
            <ul>
                <li>Virus: Muchos virus diferentes pueden causar conjuntivitis, pero el m&aacute;s com&uacute;n es el
                    adenovirus. Los virus pueden causar s&iacute;ntomas en uno o ambos ojos. Las personas con
                    conjuntivitis viral suelen experimentar ojos llorosos con secreci&oacute;n de moco y picaz&oacute;n
                    leve. Tambi&uacute;n pueden tener otros s&iacute;ntomas virales, como fiebre, dolor de garganta y
                    congesti&oacute;n.</li>
                <li>G&uacute;rmenes: Las bacterias causan conjuntivitis con mayor frecuencia en los ni&ntilde;os que en
                    los
                    adultos. La conjuntivitis bacteriana suele comenzar en un solo ojo con mucho enrojecimiento. Pero
                    puede propagarse al otro ojo. Tambi&uacute;n puede causar una gran cantidad de secreci&oacute;n
                    verde, amarilla o blanca. Muchas personas con conjuntivitis bacteriana tambi&uacute;n experimentan
                    una sensaci&oacute;n similar al escozor.</li>
            </ul>

            <h3>No infeccioso</h3>
            <p>La mayor&iacute;a de las personas asocian la conjuntivitis con una infecci&oacute;n. Y eso es
                correcto.
                Sin embargo, hay cosas que pueden causar inflamaci&oacute;n del ojo que se ven y se sienten
                similares a
                una infecci&oacute;n:</p>
            <ul>
                <li>Alergias: La causa no infecciosa m&aacute;s com&uacute;n de la conjuntivitis es una alergia. La
                    conjuntivitis al&uacute;rgica tiende a causar mucha picaz&oacute;n, afecta a ambos ojos y causa
                    secreci&oacute;n acuosa. Puede ir acompa&ntilde;ada de otros s&iacute;ntomas de alergia, como
                    estornudos y
                    congesti&oacute;n, pero no siempre es as&iacute;. Los al&uacute;rgenos comunes que pueden causar
                    conjuntivitis incluyen el polen, la caspa de las mascotas y el polvo.</li>
                <li>Irritantes: Los productos qu&iacute;micos, como los de las soluciones de limpieza, o la
                    contaminaci&oacute;n del aire pueden irritar los ojos y causar conjuntivitis. Incluso las gotas
                    para
                    los ojos pueden causar conjuntivitis. Algunas personas reaccionan a los antibi&oacute;ticos
                    t&oacute;picos que se usan para tratar la conjuntivitis, por ejemplo.</li>
                <li> Otras enfermedades: En casos raros, la conjuntivitis puede ser causada por enfermedades que
                    afectan
                    a todo el cuerpo. Algunos ejemplos son la enfermedad de Kawasaki y la enfermedad de Sjögren.
                </li>
            </ul>

            <h3>¿C&oacute;mo puedo deshacerme r&aacute;pidamente de la conjuntivitis?</h3>
            <p>Puedes tomar medidas sencillas para aliviar los s&iacute;ntomas, como usar compresas fr&iacute;as y
                analg&uacute;sicos de venta libre. Pero si la conjuntivitis se debe a alergias u otra afecci&oacute;n
                m&uacute;dica, es posible que no puedas deshacerte de ella hasta que la trates. T&oacute;mese un
                descanso de los lentes de contacto y el maquillaje de ojos hasta que sus s&iacute;ntomas mejoren.
            <h3>Cuando obtener ayuda m&uacute;dica para la conjuntivitis</h3>
            <p>La mayor&iacute;a de los casos de conjuntivitis se resuelven por s&iacute; solos. Pero si tiene alguno de
                los siguientes s&iacute;ntomas, debe consultar a un profesional de la salud:</p>
            <ul>
                <li>Dolor significativo: El escozor leve es normal, pero no deber&iacute;a tener mucho dolor. Esto
                    podr&iacute;a ser un signo de otras afecciones oculares que pueden causar enrojecimiento.</li>
                <li>Sensibilidad a la luz: Si la luz te molesta hasta el punto de que solo puedes mantener los ojos
                    abiertos en una habitaci&oacute;n oscura, esto tambi&uacute;n sugiere que algo m&aacute;s puede
                    estar sucediendo.</li>
                <li>Lesi&oacute;n en el ojo: Si recientemente recibi&oacute; un golpe en el ojo, o si tiene algo dentro,
                    es importante que le revisen el ojo. Incluso los rasgu&ntilde;os m&aacute;s leves pueden causar
                    abrasiones
                    en la c&oacute;rnea.</li>
                <li>Cambios en la visi&oacute;n: Las l&aacute;grimas pueden causar algo de borrosidad ocasional con la
                    conjuntivitis.Pero esto deber&iacute;a resolverse tan pronto como se retire la descarga.</li>
                <li>Sin mejora: Si sus s&iacute;ntomas empeoran con el tiempo, hable con un profesional de la salud
                    sobre las opciones de tratamiento. Esto tambi&uacute;n es cierto si sus s&iacute;ntomas no mejoran
                    con gotas antibi&oacute;ticas. Si tiene herpes z&oacute;ster,herpes o una afecci&oacute;n que afecta
                    su sistema inmunitario (como el VIH o el c&aacute;ncer), debe consultar a un profesional de la
                    salud.</li>
            </ul>
            <p>La intervenci&oacute;n temprana es mejor en estas situaciones.</p>
            <img src="../../../assets/newsletter/blog_01_03_conjuntivitis.jpg" class="img-fluid"
                title="preguntas frecuentes">
            <h3>Preguntas frecuentes</h3>
            <h5>¿C&oacute;mo se puede saber si la conjuntivitis es viral o bacteriana? </h5>
            <p>Puede ser dif&iacute;cil diferenciar entre la conjuntivitis viral y la bacteriana. Pero hay
                algunas pistas que pueden ayudar: Es m&aacute;s probable que la conjuntivitis viral se propague
                al otro ojo y acompa&ntilde;e a los s&iacute;ntomas del resfriado. La conjuntivitis bacteriana es
                m&aacute;s propensa a causar una secreci&oacute;n ocular m&aacute;s espesa y puede acompa&ntilde;ar a
                las infecciones de o&iacute;do en los ni&ntilde;os.</p>
            <p>Hay excepciones a cada regla, por supuesto, as&iacute; que tome estos consejos con un grano de
                sal.</p>
            <h5>¿Est&aacute; bien dejar la conjuntivitis sin tratar?</h5>
            <p>S&iacute;, en la mayor&iacute;a de los casos. La conjuntivitis suele desaparecer por s&iacute;
                sola o con medicamentos si es bacteriana. Sin embargo, algunas personas necesitan recibir
                atenci&oacute;n m&uacute;dica antes. Aseg&uacute;rese de ver la lista anterior para saber
                cu&aacute;ndo consultar a un profesional de la salud.</p>
            <h3>Conclusi&oacute;n</h3>
            <p>La conjuntivitis es una afecci&oacute;n muy com&uacute;n. La mayor&iacute;a de las veces es una
                molestia que desaparecer&aacute; por s&iacute; sola. Pero si es causada por una
                infecci&oacute;n, puede durar hasta unas pocas semanas. Hasta que sus s&iacute;ntomas
                desaparezcan, aseg&uacute;rese de lavarse las manos con frecuencia y evite tocarse los ojos. Si
                sus s&iacute;ntomas no mejoran despu&uacute;s de un par de semanas, es mejor hacerse un chequeo.
            </p>
            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">Azari, A. A., et al. (2013). <a
                    href="https://jamanetwork.com/journals/jama/article-abstract/1758756">
                    Conjuntivitis: una revisi&oacute;n sistem&aacute;tica del diagn&oacute;stico y
                    el tratamiento.</a>Revista de la Asociaci&oacute;n M&uacute;dica Americana.</p>
            <p style="font-style: oblique;" class="text-muted">
                Boyd, K. (2023).<a href="https://www.aao.org/eye-health/diseases/pink-eye-conjunctivitis">Conjuntivitis:
                    ¿Qu&eacute; es la conjuntivitis?</a> Academia Americana de Oftalmolog&iacute;a.
            </p>
        </div>
        <div class="col-lg-2">

        </div>
    </div>

    <div class="row" *ngIf="2==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>¿Cu&aacute;nto dura un resfriado y cu&aacute;les son las etapas?</h1>
            <div>
                <h3>Puntos Clave:</h3>
                <ul>
                    <li>El resfriado com&uacute;n a menudo sigue una l&iacute;nea de tiempo bastante predecible.</li>
                    <li>Los s&iacute;ntomas a menudo tardan de 1 a 3 d&iacute;as en desarrollarse y luego alcanzan su
                        punto m&aacute;ximo de
                        1 a
                        3 d&iacute;as. Los s&iacute;ntomas suelen durar entre 7 y 10 d&iacute;as. Pero puede tardar
                        hasta 3 semanas en
                        desaparecer por completo.</li>
                    <li>Los resfriados suelen desaparecer por s&iacute; solos, por lo que la mayor&iacute;a de las
                        personas no
                        necesitan
                        consultar a un profesional de la salud. Pero algunos productos de venta libre (OTC, por sus
                        siglas
                        en ingl&eacute;s) y remedios caseros pueden ayudar a aliviar los s&iacute;ntomas.</li>
                </ul>
                <p>El resfriado com&uacute;n es bastante com&uacute;n. La mayor&iacute;a de los adultos contraen entre
                    dos y cuatro
                    resfriados al
                    a&ntilde;o. Y los ni&ntilde;os obtienen a&uacute;n m&aacute;s. Los resfriados tambi&eacute;n suelen
                    aparecer en etapas espec&iacute;ficas y
                    siguen
                    una l&iacute;nea de tiempo bastante predecible. Los resfriados son m&aacute;s comunes en
                    oto&ntilde;o e invierno. Pero
                    puedes
                    conseguirlos en cualquier &eacute;poca del a&ntilde;o, incluido el verano. </p>
                <p>Los s&iacute;ntomas del resfriado suelen aparecer de forma m&aacute;s gradual que los s&iacute;ntomas
                    de la gripe o la
                    COVID-19. Conocer las etapas y los s&iacute;ntomas de un resfriado puede ayudarte a diferenciarlo de
                    otras
                    enfermedades y tratarlo adecuadamente. </p>
                <p>Una vez que un virus del resfriado ingresa a una de sus c&eacute;lulas, comienza a crear copias de
                    s&iacute; mismo.
                    Y
                    estas copias pasan a infectar a otras c&eacute;lulas. Esto puede comenzar a suceder dentro de las 8
                    a 10
                    horas
                    posteriores a la infecci&oacute;n inicial. </p>
                <p>Los s&iacute;ntomas pueden comenzar justo despu&eacute;s de eso. Pero, para la mayor&iacute;a de las
                    personas, los
                    s&iacute;ntomas
                    aparecen dentro de 1 a 3 d&iacute;as despu&eacute;s de la exposici&oacute;n al virus. Esto es
                    diferente de la gripe,
                    donde
                    los s&iacute;ntomas tienden a ser m&aacute;s intensos y aparecen m&aacute;s repentinamente.
                </p>
            </div>

            <h3>¿Cu&aacute;les son las etapas y los s&iacute;ntomas del resfriado com&uacute;n?</h3>
            <p>Los resfriados y los s&iacute;ntomas del resfriado tienden a pasar por etapas ordenadas. Pero es posible
                que
                experimente el tiempo y las etapas de manera un poco diferente. Estas son las etapas generales de un
                resfriado.
            <h3>Incubaci&oacute;n.</h3>
            <p>El tiempo de incubaci&oacute;n de un resfriado es el tiempo que transcurre desde que estuvo expuesto a un
                virus
                hasta que comienza a sentirse enfermo. Despu&eacute;s de estar expuesto a un virus del resfriado,
                generalmente
                toma de 1 a 3 d&iacute;as para que desarrolles s&iacute;ntomas. Pero es posible desarrollar
                s&iacute;ntomas tan pronto como
                10 a 12 horas despu&eacute;s de la exposici&oacute;n.
            </p>
            <h3>Los s&iacute;ntomas del resfriado alcanzan su punto m&aacute;ximo</h3>
            <p>
                Los s&iacute;ntomas del resfriado alcanzan su punto m&aacute;ximo de 1 a 3 d&iacute;as despu&eacute;s de
                que comienzan los s&iacute;ntomas.
                Este es el momento en el que eres m&aacute;s contagioso. Los principales s&iacute;ntomas incluyen:
            </p>
            <ul>
                <li>
                    Dolor de garganta</li>
                <li>Congesti&oacute;n o secreci&oacute;n nasal</li>
                <li>Tos</li>
                <li>Estornudar</li>
                <li>Cefaleas</li>
                <li>Mucosidad transparente</li>
                <li>Dolores corporales</li>
            </ul>
            <h3>Los s&iacute;ntomas disminuyen</h3>
            <p>Los s&iacute;ntomas del resfriado suelen durar de 3 a 10 d&iacute;as. Despu&eacute;s de 2 o 3 d&iacute;as
                de s&iacute;ntomas, la mucosidad
                de la nariz puede cambiar a un color blanco, amarillo o verde. Esto es normal y no significa que
                necesite un antibi&oacute;tico.
                Recuperaci&oacute;n.</p>
            <p>Alrededor del d&iacute;a 10, es probable que los peores s&iacute;ntomas hayan quedado atr&aacute;s. Pero
                algunos s&iacute;ntomas
                pueden
                durar hasta 3 semanas en algunas personas, especialmente secreci&oacute;n nasal, congesti&oacute;n nasal
                y tos.
                Aunque, en
                general, deber&iacute;as sentirte mejor y es probable que los s&iacute;ntomas no sean tan graves.
            </p>
            <div>

                <h3>S&iacute;ntomas del resfriado en adultos vs. ni&ntilde;os</h3>
                <p>Los adultos y los ni&ntilde;os experimentan muchos de los mismos s&iacute;ntomas cuando se
                    resfr&iacute;an. Pero los ni&ntilde;os
                    son
                    m&aacute;s propensos a tener fiebre que los adultos. Los ni&ntilde;os a menudo experimentan fiebre
                    en los primeros
                    3
                    d&iacute;as de la enfermedad con un resfriado. Pero tenga cuidado con la fiebre que comienza
                    despu&eacute;s de los
                    primeros d&iacute;as, ya que puede indicar una infecci&oacute;n diferente, como una infecci&oacute;n
                    de o&iacute;do.
                    Recuerde que los expertos no recomiendan medicamentos de venta libre para la tos y el resfriado para
                    ni&ntilde;os menores de 4 a&ntilde;os.
                </p>
                <h3>¿Cu&aacute;nto tiempo es contagioso?</h3>
                <p>
                    Por lo general, eres contagioso un par de d&iacute;as antes de que comiencen los s&iacute;ntomas.
                    Tambi&eacute;n puede
                    ser
                    contagioso mientras tenga s&iacute;ntomas. De hecho, es m&aacute;s probable que propague el virus en
                    los primeros
                    2 a
                    3 d&iacute;as, cuando los
                    s&iacute;ntomas alcanzan su punto m&aacute;ximo.
                </p>
                <h3>Los resfriados pueden propagarse cuando:</h3>
                <p>Inhalar aire despu&eacute;s de que una persona infectada tosa o estornude
                    Tener contacto personal cercano con alguien que est&eacute; infectado
                    Estreche la mano de alguien que est&eacute; infectado o toque una superficie infectada y luego
                    p&oacute;ngale esa
                    mano
                    en los ojos, la nariz o la boca</p>
                <p>Por lo tanto, si est&aacute; enfermo, qu&eacute;dese en casa y no vaya al trabajo o a la escuela. Si
                    su hijo est&aacute;
                    enfermo, mant&eacute;ngalo alejado de la guarder&iacute;a o la escuela. Trate de evitar el contacto
                    cercano con
                    otras
                    personas. Y use una mascarilla si debe salir en lugares concurridos. Use el codo para cubrirse la
                    boca y
                    la nariz al toser o estornudar. Y aseg&uacute;rese de lavarse las manos despu&eacute;s de toser,
                    estornudar o
                    sonarse
                    la nariz. Todos estos esfuerzos pueden ayudar a prevenir la propagaci&oacute;n del virus.
                </p>
            </div>
            <h3>Se&ntilde;ales de que el resfriado est&aacute; mejorando</h3>
            <p>Los s&iacute;ntomas espec&iacute;ficos del resfriado que experimente variar&aacute;n. Y algunos
                s&iacute;ntomas, como secreci&oacute;n o
                congesti&oacute;n nasal y tos, pueden persistir hasta 10 d&iacute;as o m&aacute;s. Pero, en general,
                debes notar que tus
                s&iacute;ntomas mejoran y tus niveles de energ&iacute;a comienzan a regresar despu&eacute;s de
                aproximadamente una semana.
            </p>
            <img src="../../../assets/newsletter/blog_02_02_resfriado.jpg" class="img-fluid" title="como tratar">
            <h3>¿C&oacute;mo se trata un resfriado? Estas son algunas formas de aliviar los s&iacute;ntomas de un
                resfriado:</h3>
            <ul>
                <li>Descansa lo suficiente.</li>
                <li>Beba muchos l&iacute;quidos.</li>
                <li>Usa un humidificador o vaporizador.</li>
                <li>Use soluci&oacute;n salina en aerosol nasal (para adultos) o gotas (para ni&ntilde;os).</li>
                <li>Chupe pastillas para la tos y pastillas para la garganta.</li>
                <li>Use miel para aliviar la tos en adultos y ni&ntilde;os de al menos 1 a&ntilde;o o m&aacute;s.</li>
            </ul>
            <h3>Los productos de venta libre que pueden ayudar con los s&iacute;ntomas incluyen:</h3>
            <ul>
                <li>Antihistam&iacute;nicos</li>
                <li>Descongestionantes (que se pueden tomar por v&iacute;a oral o rociar en la nariz)</li>
                <li>Expectorantes</li>
                <li>Medicamentos para la tos (antitusivos)</li>
                <li>Antifebriles (antipir&eacute;ticos)</li>
            </ul>
            <p>Hable con su proveedor de atenci&oacute;n primaria sobre qu&eacute; medicamentos son los mejores para
                usted. Es posible
                que algunas personas no puedan usar estos productos debido a otros medicamentos, condiciones de salud o
                s&iacute;ntomas, como fiebre alta o dolor en el pecho. Algunos de estos medicamentos tambi&eacute;n
                pueden no ser
                apropiados para los ni&ntilde;os.
            </p>
            <h3>¿Necesitas antibi&oacute;ticos para un resfriado?</h3>
            <p>No, un antibi&oacute;tico no eliminar&aacute; un resfriado. Los antibi&oacute;ticos se usan para tratar
                infecciones
                bacterianas, como infecciones del tracto urinario (ITU), acn&eacute; y faringitis estreptoc&oacute;cica.
                Pero los
                resfriados son causados por virus, por lo que los antibi&oacute;ticos no funcionar&aacute;n con ellos.
            </p>
            <h3>¿Cu&aacute;ndo se debe consultar al m&eacute;dico por un resfriado?</h3>
            <p>No hay cura para un resfriado. Por lo general, desaparece por s&iacute; solo en 2 semanas sin una visita
                al
                consultorio de su m&eacute;dico. Pero hay ocasiones en las que debe consultar a un profesional de la
                salud.
                A continuaci&oacute;n, le indicamos cu&aacute;ndo buscar ayuda:</p>
            <ul>
                <li>Sus s&iacute;ntomas no mejoran en 10 d&iacute;as.</li>
                <li>Sus s&iacute;ntomas son graves o inusuales. Por ejemplo, tiene fiebre que dura m&aacute;s de 4
                    d&iacute;as,
                    deshidrataci&oacute;n, dificultad para respirar o s&iacute;ntomas que desaparecen y regresan o
                    empeoran.</li>
                <li>Un ni&ntilde;o menor de 2 meses tiene fiebre o est&aacute; let&aacute;rgico.</li>
            </ul>
            <img src="../../../assets/newsletter/blog_02_03_resfriado.jpg" class="img-fluid" title="preguntas">
            <h3>Preguntas frecuentes</h3>
            <h5>¿Qu&eacute; es un resfriado?</h5>
            <p>Un "resfriado" no es un diagn&oacute;stico oficial. Pero algunas personas usan este t&eacute;rmino para
                describir la ubicaci&oacute;n de los s&iacute;ntomas de un resfriado com&uacute;n, como
                congesti&oacute;n o secreci&oacute;n nasal,
                dolor de cabeza y ojos llorosos. Esto contrasta con un "resfriado tor&aacute;cico", que puede referirse
                a una bronquitis aguda, una infecci&oacute;n del tracto respiratorio inferior.
            </p>
            <h5>¿Cu&aacute;nto tiempo debo quedarme en casa con un resfriado?</h5>
            <p>Si experimenta s&iacute;ntomas y se siente enfermo, es mejor quedarse en casa para recuperarse y evitar
                transmitir el virus a otras personas. Si es posible, evite tambi&eacute;n el contacto con otras
                personas en su hogar. Es m&aacute;s probable que propague el virus en los primeros 2 a 3 d&iacute;as,
                cuando
                sus s&iacute;ntomas son peores. </p>
            <p>Puede volver a sus rutinas cuando sus s&iacute;ntomas est&eacute;n mejorando y no haya tenido fiebre (sin
                usar
                un antifebril) durante 24 horas. Pero recuerde que es posible propagar el virus incluso si se
                siente bien, as&iacute; que aseg&uacute;rese de lavarse las manos con frecuencia y considere usar una
                mascarilla en espacios concurridos.
            </p>
            <h5>¿La vitamina C ayuda con los resfriados?</h5>
            <p>Muchas personas piensan que la vitamina C ayuda a prevenir y tratar los resfriados. Pero no hay
                pruebas s&oacute;lidas que respalden esto. Algunas investigaciones sugieren que tomar vitamina C
                regularmente puede reducir la duraci&oacute;n de un resfriado, pero no mucho. De todos modos, obtener
                suficiente vitamina C es una parte importante para apoyar su salud en general.
            </p>
            <h3>Conclusi&oacute;n</h3>
            <p>Los s&iacute;ntomas del resfriado suelen seguir un patr&oacute;n predecible. Los s&iacute;ntomas
                m&aacute;s comunes son dolor
                de garganta, secreci&oacute;n nasal, tos, estornudos y mucosidad. Los s&iacute;ntomas pueden durar hasta
                3
                semanas.
            <p></p>
            <p>Un resfriado es un virus, por lo que los antibi&oacute;ticos no lo eliminar&aacute;n. Pero la
                mayor&iacute;a de los
                resfriados desaparecen por s&iacute; solos. Por lo tanto, no necesita ver a un profesional de la salud
                a menos que sus s&iacute;ntomas sean graves y no comiencen a desaparecer despu&eacute;s de 10
                d&iacute;as. Puede
                tratar un resfriado con medicamentos de venta libre y descansando, bebiendo muchos l&iacute;quidos y
                usando un humidificador o aerosol nasal.
            </p>
            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">Allan, G. M., et al. (2014).<a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3928210/">Prevenci&oacute;n y
                    tratamiento del
                    resfriado com&uacute;n: Dar sentido a la evidencia.</a> Revista de la Asociaci&oacute;n
                M&eacute;dica Canadiense.
            </p>
            <p style="font-style: oblique;" class="text-muted">Asociaci&oacute;n Americana del Pulm&oacute;n (American
                Lung
                Association). (2024). <a
                    href="https://www.lung.org/lung-health-diseases/lung-disease-lookup/facts-about-the-common-cold">Datos
                    sobre el resfriado com&uacute;n.</a></p>

        </div>
        <div class="col-lg-2">
        </div>
    </div>


    <div class="row" *ngIf="3==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>Lantus vs. Ozempic: 6 diferencias a tener en cuenta</h1>

            <h3>Puntos clave:</h3>
            <ul>
                <li>
                    Lantus (insulina glargina) y Ozempic (semaglutida) son medicamentos inyectables que reducen la
                    glucosa (az&uacute;car) en la sangre. Ambos est&aacute;n aprobados para tratar la diabetes tipo 2,
                    pero solo
                    Lantus est&aacute; aprobado para la diabetes tipo 1.
                </li>
                <li>
                    Lantus es una insulina de acci&oacute;n prolongada, mientras que Ozempic es un agonista del
                    p&eacute;ptido-1
                    similar al glucag&oacute;n (GLP-1). Ozempic hace que el p&aacute;ncreas libere insulina
                    despu&eacute;s de comer y reduce
                    la producci&oacute;n de glucosa en el h&iacute;gado. Ozempic tambi&eacute;n puede reducir el
                    apetito, lo que puede causar
                    p&eacute;rdida de peso.
                </li>
                <li>
                    Lantus tiene un mayor riesgo de hipoglucemia (bajo nivel de glucosa en la sangre) que Ozempic. Pero
                    es m&aacute;s probable que Ozempic cause efectos secundarios relacionados con el intestino. En casos
                    raros,
                    ciertas afecciones graves tambi&eacute;n se han relacionado con Ozempic. Su m&eacute;dico puede
                    ayudarle a decidir
                    si uno o ambos medicamentos son buenas opciones para usted.
                </li>
            </ul>

            <p>Lantus (insulina glargina) y Ozempic (semaglutida) son medicamentos inyectables que se usan para
                reducir
                los niveles de glucosa (az&uacute;car) en la sangre en adultos con diabetes tipo 2. Ambos se
                consideran
                altamente efectivos en el tratamiento de la diabetes. Pero Lantus y Ozempic funcionan de manera
                diferente, tienen diferentes dosis recomendadas y causan diferentes efectos secundarios.
                Tambi&eacute;n
                se
                utilizan de diferentes maneras.
                Su m&eacute;dico puede recomendar agregar Lantus, Ozempic o ambos medicamentos a su plan de
                tratamiento.
                Saber
                qu&eacute; los diferencia puede ayudarlo a comprender los beneficios que ofrece cada medicamento. A
                continuaci&oacute;n, destacamos y discutimos siete diferencias clave entre Lantus y Ozempic.
            </p>

            <img src="../../../assets/newsletter/blog_03_03_lantusVSozempic.jpg" class="img-fluid" title="preguntas">
            <h3>1. Lantus y Ozempic funcionan de manera diferente</h3>
            <p>Lantus y Ozempic tratan la diabetes reduciendo los niveles de glucosa en sangre, pero logran este
                objetivo de diferentes maneras. Adem&aacute;s, Ozempic puede ayudar a controlar la diabetes al promover
                la
                p&eacute;rdida de peso. Y tambi&eacute;n tiene beneficios relacionados con el coraz&oacute;n y los
                ri&ntilde;ones, de los que
                hablaremos m&aacute;s adelante.
            </p>
            <p>Con la diabetes tipo 1 y tipo 2, el cuerpo no produce suficiente insulina o la usa incorrectamente. La
                insulina es necesaria para mover la glucosa de la sangre a las c&eacute;lulas, donde se puede utilizar
                como
                energ&iacute;a.
            </p>
            <p>Lantus es un medicamento de insulina fabricado en laboratorio. Es un tipo de insulina llamada insulina
                basal y permanece en el cuerpo durante unas 24 horas. Reemplaza o aumenta la insulina que su cuerpo
                producir&iacute;a si no tuviera diabetes.
            </p>
            <p>Ozempic no es insulina. Imita un tipo de incretina (hormona intestinal) llamada p&eacute;ptido similar al
                glucag&oacute;n-1 (GLP-1). Los medicamentos que hacen esto se llaman agonistas de GLP-1 y tienen varios
                efectos
                en el cuerpo, entre ellos:
            </p>
            <ul>
                <li>Hacer que el cuerpo libere m&aacute;s insulina despu&eacute;s de las comidas
                </li>
                <li>Reducir la cantidad de glucosa que produce el h&iacute;gado
                </li>
                <li>Ralentiza el paso de los alimentos a trav&eacute;s del est&oacute;mago, lo que ralentiza la
                    absorci&oacute;n de glucosa y
                    provoca una sensaci&oacute;n de saciedad durante m&aacute;s tiempo
                </li>
                <li>Disminuir el hambre al enfocarse en ciertas &aacute;reas del cerebro
                </li>
            </ul>
            <h3>2. Lantus y Ozempic tienen diferentes usos aprobados por la FDA</h3>
            <p>Tanto Lantus como Ozempic est&aacute;n aprobados por la FDA para tratar la diabetes tipo 2 en adultos.
                Pero
                tambi&eacute;n est&aacute;n aprobados para diferentes usos. Por ejemplo:
            </p>
            <p>Lantus tambi&eacute;n est&aacute; aprobado para tratar la diabetes tipo 1 en adultos y ni&ntilde;os
                mayores de 6 a&ntilde;os. Aunque
                Ozempic no est&aacute; aprobado para la diabetes tipo 1, algunos profesionales de la salud pueden
                recetarlo
                fuera de etiqueta.
            </p>
            <p>Ozempic est&aacute; aprobado para reducir el riesgo de eventos cardiovasculares adversos graves (como
                ataques
                card&iacute;acos, accidentes cerebrovasculares y muertes relacionadas con el coraz&oacute;n) en personas
                con diabetes
                tipo 2 y enfermedades card&iacute;acas.
            </p>
            <p>Ozempic tambi&eacute;n ha demostrado beneficios potenciales para las personas con diabetes que tienen
                enfermedad
                renal cr&oacute;nica e insuficiencia card&iacute;aca. Algunos profesionales de la salud tambi&eacute;n
                pueden recetar Ozempic
                para la prediabetes o la p&eacute;rdida de peso, pero estos son usos no indicados en la etiqueta.
            </p>

            <h3>3. Lantus y Ozempic tienen diferentes dosis recomendadas</h3>
            <p>
                Lantus suele ser una inyecci&oacute;n de una vez al d&iacute;a. Ozempic solo se administra una vez a la
                semana, por lo
                que se usa con menos frecuencia que Lantus. Puede inyectar Lantus y Ozempic por v&iacute;a
                subcut&aacute;nea (debajo
                de la piel) en el est&oacute;mago, la parte superior del brazo o la parte superior del muslo. Y puedes
                inyect&aacute;rtelos en cualquier momento del d&iacute;a, sin tener en cuenta las comidas. Pero con
                Lantus, debe
                tratar de inyectarlo a la misma hora todos los d&iacute;as.
            </p>
            <p>
                Lantus se presenta en un vial multidosis y una pluma inyectora multidosis (SoloStar). Ambos productos
                Lantus contienen 100 unidades por mL. Su dosis exacta de Lantus depender&aacute; de factores como sus
                niveles
                de glucosa en sangre y sus objetivos de tratamiento. Es posible que le receten Lantus solo o en
                combinaci&oacute;n con una insulina de acci&oacute;n m&aacute;s corta que se inyecta a la hora de las
                comidas. Lantus tambi&eacute;n
                puede combinarse con otros medicamentos inyectables u orales para la diabetes.
            </p>
            <p>
                Ozempic viene en forma de pluma inyectora que est&aacute; disponible en varias dosis. La dosis inicial
                de
                Ozempic suele ser de 0,25 mg una vez a la semana durante 4 semanas. Despu&eacute;s de 4 semanas, es
                probable
                que su dosis aumente a 0.5 mg una vez por semana. Y puede que se incremente a&uacute;n m&aacute;s a
                partir de ah&iacute;.
                Este enfoque le da a su cuerpo tiempo para adaptarse a Ozempic y le ayuda a encontrar una dosis que
                controle sus niveles de glucosa en sangre con la menor cantidad posible de efectos secundarios.
            </p>
            <img src="../../../assets/newsletter/blog_03_02_lantusVSozempic.jpg" class="img-fluid" title="dosis">
            <h3>4. Es com&uacute;n perder peso con Ozempic, pero Lantus puede hacerte subir de peso</h3>
            <p>
                Lantus y Ozempic pueden afectar el peso corporal de manera diferente. Algunas personas que toman
                Lantus
                aumentan de peso. La mayor&iacute;a de las personas que toman Ozempic pierden peso. Pero como se
                mencion&oacute;,
                Ozempic no est&aacute; aprobado por la FDA espec&iacute;ficamente para la p&eacute;rdida de peso.
                Sin
                embargo, otro producto
                de semaglutida llamado Wegovy s&iacute; lo es. Las dosis de Ozempic para la diabetes suelen ser
                m&aacute;s bajas que
                las dosis de Wegovy para la p&eacute;rdida de peso.
            </p>
            <p>
                En un estudio que compar&oacute; la semaglutida con Lantus como medicamento complementario a la
                metformina para
                la diabetes tipo 2, un tratamiento para la diabetes com&uacute;nmente recetado, la semaglutida se
                asoci&oacute; con la
                p&eacute;rdida de peso, mientras que Lantus se asoci&oacute; con el aumento de peso.
                Espec&iacute;ficamente, los
                participantes que tomaron 1 mg semanalmente perdieron alrededor de 11 libras despu&eacute;s de 7
                meses
                de uso.
                Los participantes que tomaron Lantus a diario ganaron alrededor de 3 libras en el mismo
                per&iacute;odo.
            </p>
            <p>
                En un ensayo cl&iacute;nico de Ozempic para la diabetes tipo 2, las personas que tomaron 2 mg una
                vez a
                la
                semana perdieron un promedio de 15 libras durante 9 meses. Las personas que tomaron 1 mg una vez a
                la
                semana perdieron un poco menos (13 libras).
            </p>
            <p>
                En los primeros ensayos cl&iacute;nicos de Lantus, los adultos que tomaron insulina para la diabetes
                tipo 2
                aumentaron un promedio de 4 libras en el primer a&ntilde;o de tratamiento.
            </p>

            <h3>5. Ozempic y Lantus tienen diferentes efectos secundarios a tener en cuenta</h3>
            <p>
                En general, Lantus y Ozempic causan diferentes efectos secundarios. Ambos pueden causar
                irritaci&oacute;n en el
                lugar de la inyecci&oacute;n, pero esto puede ser m&aacute;s probable con Lantus. Rotar los sitios de
                inyecci&oacute;n con
                cada dosis puede ayudar a prevenir la irritaci&oacute;n y la infecci&oacute;n en los sitios de
                inyecci&oacute;n.
            </p>
            <p>
                La hipoglucemia (bajo nivel de glucosa en la sangre) tambi&eacute;n es posible tanto con Lantus como con
                Ozempic. Pero el riesgo es mucho mayor con Lantus. La hipoglucemia no es com&uacute;n con Ozempic, pero
                el
                riesgo aumenta cuando se combina con otros medicamentos para la diabetes, como la insulina o las
                sulfonilureas.
            </p>
            <p>
                Adem&aacute;s de la hipoglucemia, otros posibles efectos secundarios de Lantus incluyen:
            </p>
            <ul>
                <li>Cefaleas
                </li>
                <li>Infecciones del tracto respiratorio superior
                </li>
                <li>Niveles bajos de potasio en sangre
                </li>
                <li>Aumento de peso
                </li>
                <li>Lipodistrofia (engrosamiento o picaduras debajo de la piel)
                </li>
            </ul>

            <p>
                Los efectos secundarios m&aacute;s comunes de Ozempic son los gastrointestinales como n&aacute;useas,
                diarrea y dolor
                de est&oacute;mago. Estos efectos secundarios a menudo mejoran por s&iacute; solos con el tiempo.
                Mientras tanto,
                seguir una dieta apta para diab&eacute;ticos que limite los alimentos ricos en almid&oacute;n,
                az&uacute;car y grasa puede
                ayudar. Y puede encontrar otros consejos para controlar las n&aacute;useas, la diarrea o el
                estre&ntilde;imiento
                causados por Ozempic a trav&eacute;s de GoodRx Health.
                Aunque son raros, tambi&eacute;n se han relacionado efectos secundarios graves con Ozempic. Entre ellas
                se
                encuentran:
            </p>
            <ul>
                <li>Pancreatitis
                </li>
                <li>Problemas de la ves&iacute;cula biliar
                </li>
                <li>Problemas renales
                </li>
                <li>Problemas de visi&oacute;n
                </li>
                <li>Tumores de tiroides
                </li>
                <li>Vaciamiento g&aacute;strico lento (gastroparesia)
                </li>
            </ul>

            <p>
                Antes de empezar a tomar cualquiera de los medicamentos, aseg&uacute;rate de que la persona que te
                recete
                conozca todos tus antecedentes m&eacute;dicos, incluso si tienes antecedentes familiares de
                c&aacute;ncer de tiroides
                o si planeas quedar embarazada. Se recomienda dejar de tomar Ozempic 2 meses antes de intentar quedar
                embarazada. Mientras tanto, la insulina es el medicamento de elecci&oacute;n para tratar la diabetes
                tipo 1 y
                tipo 2 durante el embarazo.
            </p>
            <h3>6. Lantus tiene biosimilares y alternativas gen&eacute;ricas, pero Ozempic es solo de marca</h3>
            <p>
                Ozempic es un medicamento de marca &uacute;nica. Si bien Lantus tambi&eacute;n es un producto de marca,
                tambi&eacute;n est&aacute;n
                disponibles varios productos que son intercambiables con Lantus. Esto incluye los biosimilares de Lantus
                Semglee (insulina glargina-yfgn) y Rezvoglar (insulina glargina-aglr).
            </p>
            <p>
                Los biosimilares son medicamentos muy similares a los biol&oacute;gicos, como Lantus. Los biosimilares
                tienen
                los mismos beneficios y riesgos que los productos originales (de referencia) en los que se basan. Y se
                consideran intercambiables con los productos originales. Debido a esto, es posible que su
                farmac&eacute;utico
                pueda cambiarlo de Lantus a uno de sus biosimilares sin comunicarse con su recetador.
            </p>
            <p>
                Semglee es un producto de insulina de marca, pero el fabricante tambi&eacute;n fabrica una
                versi&oacute;n sin marca
                (llamada insulina glargina-yfgn). Los medicamentos sin marca son similares a los gen&eacute;ricos
                autorizados,
                pero para los biol&oacute;gicos.
            </p>
            <p>
                Otro medicamento para la insulina glargina, Basaglar, es similar a Lantus, pero no est&aacute; aprobado
                como
                biosimilar. Esto significa que los dos medicamentos no son intercambiables. Necesitar&aacute; una nueva
                receta
                de Basaglar si su m&eacute;dico cree que es la mejor opci&oacute;n para usted.
            </p>
            <h3>¿Se puede tomar Lantus y Ozempic juntos?</h3>
            <p>
                S&iacute;, Ozempic (y otros agonistas del GLP-1) se pueden combinar con insulina para controlar la
                diabetes
                tipo 2. De hecho, esta combinaci&oacute;n puede compensar el aumento de peso causado por la insulina. Si
                le
                recetan tanto Lantus como Ozempic, se pueden inyectar en la misma &aacute;rea general siempre que los
                sitios no
                est&eacute;n uno al lado del otro.
            </p>
            <p>
                El hecho de que debas tomar m&aacute;s de un medicamento para la diabetes a la vez depende de tus
                niveles de
                glucosa y de tus objetivos generales de tratamiento. Su m&eacute;dico puede analizar sus opciones con
                usted, ya
                que hay varios medicamentos que tratan la diabetes de manera efectiva. Esto incluye opciones orales e
                inyectables.
            </p>
            <h3>Conclusi&oacute;n</h3>
            <p>
                Lantus (insulina glargina) y Ozempic (semaglutida) son dos medicamentos inyectables que se utilizan para
                reducir la glucosa (az&uacute;car) en la sangre. Se pueden recetar solos o juntos para adultos con
                diabetes
                tipo 2. Pero solo Lantus est&aacute; aprobado para adultos y ni&ntilde;os con diabetes tipo 1. Y solo
                Ozempic est&aacute;
                aprobado para reducir el riesgo de eventos cardiovasculares graves en personas con diabetes tipo 2 y
                enfermedades card&iacute;acas.
            </p>
            <p>
                Los medicamentos difieren en otros aspectos. Por ejemplo, Lantus generalmente se inyecta una vez al
                d&iacute;a,
                mientras que Ozempic se inyecta una vez a la semana. Lantus puede causar aumento de peso, mientras que
                Ozempic es conocido por causar p&eacute;rdida de peso. Y Lantus tiene un riesgo significativamente mayor
                de
                causar hipoglucemia (bajo nivel de az&uacute;car en la sangre) que Ozempic. Tambi&eacute;n tienen
                diferentes efectos
                secundarios potenciales.
            </p>
            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">Comit&eacute; de Pr&aacute;ctica Profesional de la
                Asociaci&oacute;n Americana de Diabetes. (2024). <a
                    href="https://diabetesjournals.org/care/article/47/Supplement_1/S158/153955/9-Pharmacologic-Approaches-to-Glycemic-Treatment">Cap&iacute;tulo
                    9: Enfoques farmacol&oacute;gicos para el tratamiento gluc&eacute;mico.</a> Est&aacute;ndares de
                atenci&oacute;n en diabetes,
                2024.
            </p>
            <p style="font-style: oblique;" class="text-muted">Comit&eacute; de Pr&aacute;ctica Profesional de la
                Asociaci&oacute;n Americana de Diabetes. (2024). <a
                    href="https://diabetesjournals.org/care/article/47/Supplement_1/S282/153948/15-Management-of-Diabetes-in-Pregnancy-Standards">Cap&iacute;tulo
                    15: manejo de la diabetes en el embarazo.</a> Est&aacute;ndares de atenci&oacute;n en diabetes,
                2024.
            </p>

        </div>
        <div class="col-lg-2">
        </div>
    </div>

    <div class="row" *ngIf="4==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>7 formas de prevenir un resfriado cuando lo sientes venir</h1>
            <h3>Puntos clave:</h3>
            <ul>
                <li>Los resfriados son causados por virus que pueden sobrevivir durante horas en superficies como
                    manijas de
                    puertas y encimeras. Esto es lo que hace que los resfriados sean tan contagiosos y dif&iacute;ciles
                    de
                    evitar.</li>
                <li>Dormir lo suficiente, reducir el estr&eacute;s y llevar una dieta saludable puede ayudarlo a
                    desarrollar
                    un sistema inmunol&oacute;gico fuerte para combatir los virus del resfriado.</li>
                <li>Si te resfr&iacute;as, los s&iacute;ntomas no suelen ser graves.</li>
                <li>Los remedios de venta libre (OTC, por sus siglas en ingl&eacute;s) pueden ayudar a aliviar los
                    s&iacute;ntomas mientras te recuperas.</li>
            </ul>
            <p>
                Contraer un resfriado es inevitable para la mayor&iacute;a de las personas. Si bien los s&iacute;ntomas
                del
                resfriado suelen ser leves y de corta duraci&oacute;n, los estornudos, la secreci&oacute;n nasal y el
                dolor
                de
                garganta pueden durar hasta 2 semanas. Estos s&iacute;ntomas tienden a resolverse por s&iacute; solos,
                pero
                pueden ser molestos y
                la mayor&iacute;a de las personas quieren evitarlos.
            </p>
            <p>Afortunadamente, hay cosas que puedes hacer para reducir el riesgo de contraer un resfriado.</p>
            <h3>¿C&oacute;mo se puede detener un resfriado antes de que comience?</h3>
            <p>La mejor manera de detener por completo un resfriado antes de que comience es evitar a las personas que
                tienen un resfriado. Pero esto puede ser dif&iacute;cil. Esto se debe a que puede propagar el virus del
                resfriado antes de desarrollar s&iacute;ntomas. No solo eso, m&aacute;s de 200 virus diferentes causan
                el
                resfriado
                com&uacute;n. Por lo tanto, esquivarlos es dif&iacute;cil.</p>
            <p>¿Qu&eacute; puedes hacer una vez que sientas que te pica la garganta y la nariz que gotea? Echemos un
                vistazo a c&oacute;mo la investigaci&oacute;n respalda siete remedios para el resfriado com&uacute;n.
            </p>
            <img src="../../../assets/newsletter/blog_04_02_prevenirResfriado.jpg" class="img-fluid"
                title="Estrategias">
            <h3>1. Reducir el estr&eacute;s</h3>
            <p>Las investigaciones demuestran que el estr&eacute;s puede reducir la respuesta inmunitaria. Un sistema
                inmunitario debilitado puede hacerte m&aacute;s susceptible a muchas infecciones diferentes.
                En un estudio, los investigadores encontraron que las personas que reportaron niveles m&aacute;s altos
                de
                estr&eacute;s psicol&oacute;gico ten&iacute;an tasas m&aacute;s altas de infecci&oacute;n cuando se
                expusieron a un virus del
                resfriado. Cuanto mayor sea el nivel de estr&eacute;s reportado, mayores ser&aacute;n las tasas de
                infecci&oacute;n.
            </p>
            <h3>2. Prueba el zinc</h3>
            <p>El zinc es un mineral esencial que se encuentra en los alimentos que comemos. El zinc tambi&eacute;n se
                comercializa como un suplemento para ayudar a prevenir los resfriados. Existe evidencia de que el
                zinc puede reducir la gravedad y la duraci&oacute;n de un resfriado. Pero para obtener este beneficio,
                debe
                comenzar a tomar zinc dentro de las 24 horas posteriores a notar los primeros s&iacute;ntomas del
                resfriado.</p>
            <p>Es posible que comiences a sentirte mejor aproximadamente un d&iacute;a antes, en promedio.
                Desafortunadamente, no hay datos que demuestren que el zinc pueda evitar que te resfries en primer
                lugar. Lo mejor es ce&ntilde;irse a las pastillas de zinc o a los suplementos que se toman por
                v&iacute;a
                oral.
                Los aerosoles nasales de zinc se han relacionado con la p&eacute;rdida del olfato, por lo que es posible
                que desee evitarlos.
            </p>
            <h3>3. Toma vitamina D si tus niveles son bajos</h3>
            <p>Los suplementos de vitamina D no evitar&aacute;n que te resfr&iacute;es si tienes niveles normales de
                vitamina D.
                Pero muchas personas tienen niveles de vitamina D m&aacute;s bajos de lo normal, especialmente en
                invierno.
                Dado que la vitamina D desempe&ntilde;a un papel clave en el mantenimiento de la salud del sistema
                inmunitario, tiene sentido asegurarse de mantener niveles saludables de vitamina D. De hecho, hay
                evidencia de que las personas con niveles bajos de vitamina D que toman suplementos de vitamina D se
                resfr&iacute;an menos.
            </p>
            <h3>4. Bebe muchos l&iacute;quidos</h3>
            <p>No hay evidencia cient&iacute;fica de que beber muchos l&iacute;quidos ayude a prevenir o tratar un
                resfriado. Dicho esto, es de sabidur&iacute;a convencional reemplazar cualquier l&iacute;quido que
                pierda,
                especialmente si tiene diarrea o v&oacute;mitos. Pero no te excedas. La hidrataci&oacute;n excesiva con
                agua
                pura puede provocar complicaciones peligrosas, como niveles bajos de sodio en la sangre (hiponatremia),
                tambi&eacute;n llamada intoxicaci&oacute;n por agua.</p>
            <h3>5. Come alimentos nutritivos</h3>
            <p>Los alimentos que consume proporcionan los componentes b&aacute;sicos para un cuerpo sano. Desarrollar su
                sistema inmunol&oacute;gico requiere obtener una nutrici&oacute;n adecuada durante todo el a&ntilde;o,
                no
                solo cuando est&aacute; expuesto a un virus del resfriado. Priorizar los alimentos nutritivos todos los
                d&iacute;as significa que est&aacute; listo para luchar contra el virus del resfriado cuando
                inevitablemente
                golpea. Tus elecciones de alimentos tambi&eacute;n son importantes cuando comienzas a notar los
                s&iacute;ntomas del resfriado. Algunos alimentos, como el ajo, el jengibre y la miel, pueden mejorar los
                s&iacute;ntomas. Mientras tanto, otros alimentos con alto contenido de az&uacute;car refinada
                podr&iacute;an
                empeorarlos.</p>
            <h3>6. Descansa</h3>
            <p>Dormir bien con regularidad puede ayudar a defenderse de los resfriados. Algunas evidencias sugieren que
                las
                personas que duermen menos de 6 horas por noche se resfr&iacute;an m&aacute;s f&aacute;cilmente que las
                que
                duermen m&aacute;s de 7 horas. Otro estudio mostr&oacute; que las personas que dorm&iacute;an menos de 7
                horas por noche ten&iacute;an al menos tres veces m&aacute;s probabilidades de desarrollar
                s&iacute;ntomas
                de resfriado que aquellas que dorm&iacute;an m&aacute;s de 8 horas por noche.</p>
            <p>Lo mejor es esforzarse por dormir al menos de 7 a 9 horas cada noche para tener la mejor salud. Y esto
                puede
                ayudar a mantener a raya los s&iacute;ntomas del resfriado.</p>
            <h3>7. L&aacute;vate las manos</h3>
            <p>
                Los CDC estiman que 1 de cada 5 enfermedades respiratorias podr&iacute;an prevenirse lav&aacute;ndose
                las
                manos de
                rutina con agua y jab&oacute;n. Cuando est&eacute; en espacios p&uacute;blicos, es posible que desee ser
                m&aacute;s consciente
                de mantener las manos limpias. Si no puede lavarse las manos con agua y jab&oacute;n, puede usar un
                desinfectante de manos con al menos un 60 % de alcohol despu&eacute;s de tocar cualquier superficie.
                Si bien es posible que esto no ayude a sus s&iacute;ntomas una vez que se desarrollen, puede ayudar a
                evitar que propague g&eacute;rmenes a otras personas.
            </p>
            <h3>¿Cu&aacute;ndo debe consultar a un m&eacute;dico para su resfriado?</h3>
            <p>La mayor&iacute;a de los resfriados desaparecen por s&iacute; solos. Pero a veces pueden convertirse
                en
                algo m&aacute;s grave, especialmente si tienes una afecci&oacute;n m&eacute;dica subyacente. Llame a
                un
                profesional de la salud si:</p>
            <ul>
                <li>Sus s&iacute;ntomas comienzan a mejorar, pero luego empeoran</li>
                <li>Sus s&iacute;ntomas duran 10 d&iacute;as o m&aacute;s</li>
                <li>La fiebre dura m&aacute;s de 3 d&iacute;as</li>
                <li>Tiene dificultad para respirar</li>
                <li>Tiene dolor o mareos en el pecho</li>
                <li>Tose mucosidad con sangre</li>
            </ul>
            <h3>Preguntas frecuentes</h3>
            <img src="../../../assets/newsletter/blog_04_03_prevenirResfriado.jpg" class="img-fluid" title="preguntas">
            <h5>¿Por qu&eacute; sigo resfri&aacute;ndome?</h5>
            <p>Una de las razones es que hay m&aacute;s de 200 virus responsables del resfriado com&uacute;n. Y el
                rinovirus, el virus m&aacute;s com&uacute;n del resfriado, puede tener una estrategia &uacute;nica
                para infectar
                a tantas personas como sea posible, tantas veces como sea posible. B&aacute;sicamente, inunda el
                espacio con m&uacute;ltiples versiones de s&iacute; mismo, lo que aumenta las posibilidades de
                atravesar
                el sistema inmunol&oacute;gico de las personas.</p>
            <p> Pero tambi&eacute;n hay otras razones. Algunas personas son m&aacute;s propensas a resfriarse que
                otras.
                Esto podr&iacute;a depender de varias cosas, entre ellas:</p>
            <ul>
                <li>Gen&eacute;tica individual</li>
                <li>Factores laborales</li>
                <li>Riesgos en el hogar</li>
                <li>Niveles de estr&eacute;s</li>
                <li>Calidad del sue&ntilde;o</li>
                <li>Dieta</li>
                <li>Antecedentes de tabaquismo</li>
                <li>H&aacute;bitos de higiene de manos</li>
            </ul>
            <h3>¿Tomar medicamentos de venta libre para el resfriado puede ayudar a prevenir un resfriado?</h3>
            <p>No, los medicamentos de venta libre para el resfriado no previenen un resfriado ni lo acortan. Pero
                pueden
                hacer que los s&iacute;ntomas del resfriado sean m&aacute;s f&aacute;ciles de tolerar mientras el cuerpo
                se
                recupera de la infecci&oacute;n. Estos incluyen medicamentos como:</p>
            <ul>
                <li>Analg&eacute;sicos y antifebriles (como paracetamol e ibuprofeno)</li>
                <li>Descongestionantes (como la pseudoefedrina)</li>
                <li>Aerosoles nasales (como fluticasona)</li>
                <li>Medicamentos para la tos (como guaifenesina y dextrometorfano)</li>
            </ul>
            <h3>¿La vitamina C ayuda con los resfriados?</h3>
            <p>A pesar de que muchas personas recurren a los suplementos de vitamina C cuando comienzan a
                sentirse enfermas, y muchos remedios de venta libre para el resfriado incluyen vitamina C,
                no hay evidencia s&oacute;lida de que los suplementos de vitamina C ayuden a prevenir o tratar un
                resfriado.</p>
            <p> Si bien la vitamina C desempe&ntilde;a un papel en la funci&oacute;n del sistema
                inmunol&oacute;gico, la mayor&iacute;a
                de las personas obtienen m&aacute;s que suficiente vitamina C de sus dietas. Aumentar los niveles
                de vitamina C m&aacute;s all&aacute; de lo que se considera "normal" no proporciona ning&uacute;n
                beneficio
                adicional.</p>

            <h3>La conclusi&oacute;n</h3>
            <p>
                Puede contraer un resfriado en cualquier &eacute;poca del a&ntilde;o, pero mantenerse alejado de
                lugares
                concurridos y personas enfermas puede reducir sus posibilidades. Descansar lo suficiente,
                comer sano y reducir el estr&eacute;s tambi&eacute;n puede reducir el riesgo de enfermarse si
                est&aacute;
                expuesto a un resfriado. Una vez que sienta que aparecen los s&iacute;ntomas, algunos suplementos y
                medicamentos de venta libre pueden ayudar a acortar el tiempo que est&aacute; enfermo.
                Tambi&eacute;n
                pueden reducir los s&iacute;ntomas y ayudarlo a sentirse mejor. </p>
            <p>
                La buena noticia es que el resfriado com&uacute;n generalmente no causa una enfermedad grave en la
                mayor&iacute;a de las personas y desaparece por s&iacute; solo. Pero si sus s&iacute;ntomas empeoran
                y no
                mejoran, inf&oacute;rmeselo a su equipo de atenci&oacute;n m&eacute;dica.
            </p>
            <h3>¿Por qu&eacute; confiar en nuestros expertos?</h3>
            <p>Escrito por: Dr. Javier Le&oacute;n Chavarr&iacute;a
                Farmac&eacute;utico con m&aacute;s de ocho a&ntilde;os de liderazgo en QOL CLINICAL y el
                &aacute;mbito acad&eacute;mico, me
                especializo en la transformaci&oacute;n digital del Seguimiento Farmacoterap&eacute;utico (SFT).
                Experiencia abarca desde el acompa&ntilde;amiento a la medicaci&oacute;n hasta la
                implementaci&oacute;n de
                tecnolog&iacute;as de la informaci&oacute;n en la gesti&oacute;n de salud.
            </p>
            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">Centros para el Control y la Prevenci&oacute;n de
                Enfermedades. (2024).<a href="https://www.cdc.gov/handwashing/">Sobre el lavado de manos.</a></p>
            <p style="font-style: oblique;" class="text-muted">Centros para el Control y la Prevenci&oacute;n de
                Enfermedades. (2024).<a
                    href="https://www.cdc.gov/clean-hands/about/hand-sanitizer.html?CDC_AAref_Val=https://www.cdc.gov/handwashing/hand-sanitizer-use.html">Pautas
                    y recomendaciones sobre el
                    desinfectante de manos.</a></p>
        </div>
        <div class="col-lg-2">
        </div>
    </div>

    <div class="row" *ngIf="5==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>Las 6 mejores cosas para beber cuando tienes diarrea (m&aacute;s 4 bebidas que debes evitar)</h1>
            <h3>Puntos clave:</h3>
            <ul>
                <li>La diarrea causa p&eacute;rdidas de l&iacute;quidos y electrolitos que pueden llevar a la
                    deshidrataci&oacute;n.</li>
                <li>Las mejores bebidas para la diarrea incluyen l&iacute;quidos claros como agua y bebidas que
                    contienen electrolitos, que pueden reemplazar el l&iacute;quido y los electrolitos perdidos.</li>
                <li>Algunas bebidas pueden empeorar la diarrea. Evite la cafe&iacute;na, el alcohol y las bebidas
                    azucaradas cuando tenga diarrea.</li>
            </ul>
            <p>
                Puede que la diarrea no sea el tema de conversaci&oacute;n m&aacute;s divertido, pero es muy
                com&uacute;n. ¿Sab&iacute;as que la mayor&iacute;a de las personas en Am&eacute;rica?
                experimentar&aacute;n diarrea al menos una vez al a&ntilde;o?
                La mayor&iacute;a de las personas con diarrea mejoran por s&iacute; solas en unos pocos d&iacute;as.
                Pero la diarrea puede provocar deshidrataci&oacute;n porque el cuerpo est&aacute; perdiendo
                l&iacute;quido y electrolitos adicionales. La deshidrataci&oacute;n puede ser peligrosa, especialmente
                para los adultos mayores y los ni&ntilde;os peque&ntilde;os. Lo que bebe cuando tiene diarrea puede
                ayudarlo a sentirse mejor m&aacute;s r&aacute;pido y evitar que desarrolle deshidrataci&oacute;n.
            </p>
            <h3>¿Qu&eacute; es lo mejor que se puede beber cuando se tiene diarrea?</h3>
            <img src="../../../assets/newsletter/blog_05_02_diarrea.jpg" class="img-fluid" title="Mantenerse Hidratado">
            <p>El adulto promedio debe beber alrededor de 8 onzas de l&iacute;quidos cada vez que tiene una
                defecaci&oacute;n blanda. Esto ayuda a reemplazar los l&iacute;quidos y electrolitos perdidos para que
                pueda evitar la deshidrataci&oacute;n. Mantenerse hidratado no solo te ayuda a sentirte mejor, sino que
                tambi&eacute;n permite que cada parte de tu cuerpo funcione al m&aacute;ximo para que puedas recuperarte
                m&aacute;s r&aacute;pidamente.</p>
            <ul>
                <li>Algunos alimentos pueden empeorar la diarrea. Estos son algunos alimentos que debe evitar cuando
                    tenga diarrea.</li>
                <li>¿Busca medicamentos que puedan aliviar la diarrea? Eche un vistazo a nuestra gu&iacute;a de las
                    mejores opciones de venta libre para aliviar la diarrea.</li>
                <li>¿Est&aacute; lidiando con la diarrea de la gripe estomacal? A continuaci&oacute;n, te indicamos
                    cu&aacute;nto tiempo eres contagioso con el norovirus y c&oacute;mo evitar transmitirlo a otras
                    personas.</li>
            </ul>
            <p>Estas son las mejores cosas que puedes beber cuando tienes diarrea.</p>
            <h3>1. Agua</h3>
            <p>No es una gran sorpresa ver el agua en la parte superior de esta lista. No solo es bueno para usted, sino
                que el agua tambi&eacute;n reemplaza los l&iacute;quidos perdidos sin az&uacute;car ni productos
                qu&iacute;micos agregados.
                Si no puedes soportar beber tanta agua, prueba las paletas heladas sin az&uacute;car. Si bien contienen
                colorantes y otros ingredientes, tienen un alto contenido de agua y pueden romper la monoton&iacute;a
                del agua potable.</p>
            <h3>2. Bebidas con electrolitos</h3>
            <p>Cuando tienes diarrea, pierdes agua y electrolitos. Las bebidas electrol&iacute;ticas prefabricadas
                cumplen una doble funci&oacute;n al reemplazar ambos. Tambi&eacute;n puedes alternar entre bebidas de
                agua y electrolitos.
                Si bien la mayor&iacute;a de los adultos pueden arregl&aacute;rselas con agua, los ni&ntilde;os y los
                beb&eacute;s deben obtener algunos electrolitos cuando tienen diarrea.
                "Para los beb&eacute;s menores de 6 meses, el agua adicional en la dieta puede causar problemas como la
                intoxicaci&oacute;n por agua".</p>
            <p>Los expertos recomiendan soluciones de rehidrataci&oacute;n oral, como Pedialyte, para los beb&eacute;s
                con diarrea. Estas soluciones de rehidrataci&oacute;n oral son m&aacute;s bajas en az&uacute;car que las
                bebidas deportivas. Muchas soluciones de rehidrataci&oacute;n oral tambi&eacute;n est&aacute;n
                disponibles como paletas heladas.</p>
            <h3>3. Caldo</h3>
            <p>El caldo de sopa comprado en la tienda contiene agua y electrolitos. Puedes optar por caldo de pollo,
                ternera o verduras.
                Beber caldo por s&iacute; solo probablemente no sea suficiente para prevenir la deshidrataci&oacute;n:
                no muchas personas pueden beber tanto caldo en un d&iacute;a. Pero es otra herramienta que puedes usar
                para mantenerte hidratado.
                Si no siente demasiadas n&aacute;useas, puede probar una sopa m&aacute;s ligera hecha con caldo claro
                como la sopa de pollo con fideos. Esto le ofrece tanto los beneficios del caldo como un poco de
                nutrici&oacute;n adicional del pollo y los fideos. Adem&aacute;s, es f&aacute;cil para el
                est&oacute;mago.</p>
            <h3>4. Jugo de fruta sin pulpa</h3>
            <p>Tambi&eacute;n puedes probar un poco de jugo de frutas. Pero hay un par de advertencias para este.
                Primero, quieres un jugo sin pulpa. Y en segundo lugar, hay que diluirlo. Tu bebida debe ser mitad agua
                y mitad jugo.
                Para los ni&ntilde;os, puede ofrecer jugo de frutas como un descanso de las soluciones de
                rehidrataci&oacute;n oral. Pero no conf&iacute;es demasiado en &eacute;l. Existe evidencia de que los
                ni&ntilde;os que beben demasiado jugo de fruta tardan m&aacute;s en recuperarse de la diarrea.</p>
            <h3>5. T&eacute; de jengibre</h3>
            <p>El jengibre tiene muchos efectos beneficiosos para la salud, y hay algunas pruebas de que puede aliviar
                la diarrea y el malestar estomacal.
                Intente agregar una taza o dos de t&eacute; de jengibre a su d&iacute;a. No solo obtendr&aacute;s un
                poco de agua extra, sino que el jengibre tambi&eacute;n puede ayudar a calmar tu intestino. El t&eacute;
                de jengibre est&aacute; disponible en las tiendas de comestibles. Puedes hacer tu propio t&eacute;
                hirviendo ra&iacute;z de jengibre en agua y luego dej&aacute;ndolo hervir a fuego lento durante 5
                minutos. ¡Luego cuela el l&iacute;quido y bebe a sorbos!</p>
            <h3>6. K&eacute;fir</h3>
            <p>Es una bebida fermentada a base de leche que contiene probi&oacute;ticos. Los probi&oacute;ticos pueden
                ayudar a mantener la salud intestinal. Tambi&eacute;n pueden ayudar a reemplazar cualquier bacteria
                buena que se pierda por una enfermedad diarreica.
                Al igual que el caldo de huesos, no podr&aacute;s mantenerte hidratado solo bebiendo k&eacute;fir. Pero
                agregar una bebida diaria puede ayudar a restablecer las bacterias buenas en su intestino, lo que puede
                ayudarlo a recuperarse.</p>
            <h3>¿Qu&eacute; bebidas debes evitar cuando tienes diarrea?</h3>
            <p>Algunas bebidas pueden hacer m&aacute;s da&ntilde;o que bien cuando se trata de diarrea. Aseg&uacute;rate
                de evitar estas cuatro bebidas que pueden aumentar el riesgo de deshidrataci&oacute;n.</p>
            <ul>
                <li>Bebidas con cafe&iacute;na: Las bebidas con cafe&iacute;na como el caf&eacute;, el t&eacute;, los
                    refrescos y las bebidas energ&eacute;ticas pueden aumentar el riesgo de desarrollar
                    deshidrataci&oacute;n. La cafe&iacute;na en realidad te hace orinar con m&aacute;s frecuencia, lo
                    que se suma a la p&eacute;rdida general de l&iacute;quidos.</li>
                <li>Alcohol: Al igual que la cafe&iacute;na, el alcohol hace orinar con m&aacute;s frecuencia, lo que
                    aumenta el riesgo de deshidrataci&oacute;n. Pero eso no es todo. De hecho, el alcohol puede empeorar
                    la diarrea porque evita que el intestino grueso retenga agua. El alcohol causa da&ntilde;o directo
                    al revestimiento intestinal y esto puede prolongar la recuperaci&oacute;n.</li>
                <li>Bebidas azucaradas: Es posible que recuerdes que uno de tus padres o un pariente mayor te dijo que
                    bebieras refrescos sin gas, como el ginger ale, para el malestar estomacal y la diarrea. Pero los
                    investigadores descubrieron que no ayudan. Las gaseosas tienen demasiada az&uacute;car y no
                    suficientes electrolitos para ayudar con la p&eacute;rdida de l&iacute;quidos. Adem&aacute;s, las
                    bebidas con alto contenido de az&uacute;car pueden empeorar la diarrea. La alta carga de
                    az&uacute;car evita que el intestino grueso retenga agua.</li>
                <li>Leche: Algunas personas tienen problemas para digerir los az&uacute;cares de la leche cuando tienen
                    diarrea. Esto se debe a que la inflamaci&oacute;n en el intestino da&ntilde;a las prote&iacute;nas
                    que descomponen los az&uacute;cares de la leche. Su cuerpo puede tardar un tiempo en recuperarse.
                    Por lo tanto, si nota que su diarrea empeora cuando bebe leche, mant&eacute;ngase alejado de los
                    productos l&aacute;cteos hasta que se recupere por completo.</li>
            </ul>
            <h3>¿Qu&eacute; tipo de alimentos debes comer si tienes diarrea?</h3>
            <p>No tiene que restringir su dieta si tiene diarrea. Es seguro comer lo que coma normalmente, siempre y
                cuando no empeore sus s&iacute;ntomas.
                Pero a algunas personas les resulta m&aacute;s f&aacute;cil tolerar alimentos blandos cuando tienen
                diarrea o malestar estomacal. Algunos alimentos que pueden ser m&aacute;s f&aacute;ciles para el
                est&oacute;mago incluyen:</p>
            <ul>
                <li>Tostar</li>
                <li>Galletas saladas</li>
                <li>Patatas simples</li>
                <li>Pasta simple</li>
                <li>Sopa clara y caldos</li>
                <li>Huevos</li>
                <li>Cereales cocidos, como la crema de trigo o la avena</li>
                <li>Tofu</li>
                <li>Carne magra, como pechuga de pollo</li>
                <li>Verduras blandas, como espinacas o zanahorias cocidas</li>
                <li>T&eacute;</li>
            </ul>
            <h3>¿Cu&aacute;ndo debe recibir atenci&oacute;n m&eacute;dica para la diarrea?</h3>
            <p>Por lo general, la diarrea desaparece en unos pocos d&iacute;as. Si sigues teniendo diarrea
                despu&eacute;s de una semana o crees que podr&iacute;as estar deshidratado, busca atenci&oacute;n
                m&eacute;dica lo antes posible
                Debe recibir atenci&oacute;n m&eacute;dica de inmediato si presenta alguno de los siguientes
                s&iacute;ntomas:</p>
            <ul>
                <li>Sangre en las heces</li>
                <li>Heces negras o pegajosas y alquitranadas</li>
                <li>Fiebre</li>
                <li>Dolor de est&oacute;mago intenso</li>
            </ul>
            <h3>Preguntas frecuentes</h3>
            <img src="../../../assets/newsletter/blog_05_03_diarrea.jpg" class="img-fluid" title="preguntas">
            <h5>¿Cu&aacute;nto dura la diarrea?</h5>
            <p>Depende de la causa de la diarrea. Por lo general, la diarrea desaparece en unos pocos d&iacute;as si es
                causada por un virus o una bacteria.</p>
            <h5>¿La deshidrataci&oacute;n puede causar diarrea?</h5>
            <p>La deshidrataci&oacute;n no suele causar diarrea, excepto en situaciones muy graves. Pero la diarrea
                s&iacute; causa deshidrataci&oacute;n. Los adultos mayores y los ni&ntilde;os pueden deshidratarse muy
                r&aacute;pidamente si desarrollan diarrea.</p>
            <h3>La conclusi&oacute;n</h3>
            <p>La diarrea es un problema com&uacute;n. Lo que bebe cuando tiene diarrea puede hacer que se sienta mejor
                y evitar que se deshidrate. Las mejores cosas que puede beber cuando tiene diarrea son l&iacute;quidos
                claros (como agua), bebidas con electrolitos y caldo de sopa. Evite las bebidas con cafe&iacute;na, las
                bebidas azucaradas y el alcohol cuando tenga diarrea.</p>
            <p>Por lo general, la diarrea mejora por s&iacute; sola en unos pocos d&iacute;as. Busque atenci&oacute;n
                m&eacute;dica si sus s&iacute;ntomas duran m&aacute;s de una semana o si cree que puede estar
                deshidratado</p>
            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">Aghsaeifard, Z., et al. (2022).<a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9464461/"> Comprender el uso de la terapia de
                    rehidrataci&oacute;n oral: una
                    revisi&oacute;n narrativa desde la pr&aacute;ctica cl&iacute;nica hasta las principales
                    recomendaciones.
                </a>Informes de Ciencias de la Salud.</p>
            <p style="font-style: oblique;" class="text-muted">Bishensari, F., et al. (2017).<a
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5513683/"> El alcohol y la inflamaci&oacute;n
                    intestinal.</a> Investigaci&oacute;n sobre el
                alcohol.</p>
        </div>
        <div class="col-lg-2">
        </div>
    </div>

    <div class="row" *ngIf="6==newsletterid">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <h1>¿La vacuna contra la gripe puede enfermarte? Datos e informaci&oacute;n de seguridad</h1>
            <h3>Puntos clave:</h3>
            <ul>
                <li>La vacuna contra la gripe es segura. Millones de personas durante muchas d&eacute;cadas se han
                    vacunado contra la gripe sin enfermarse.</li>
                <li>Los efectos secundarios temporales de la gripe pueden hacer que se sienta enfermo, pero no puede
                    contraer la gripe real de la vacuna.</li>
                <li>Todav&iacute;a hay algunos riesgos para la salud asociados con la vacuna contra la gripe. Estos
                    incluyen una reacci&oacute;n al&eacute;rgica y una afecci&oacute;n llamada s&iacute;ndrome de
                    Guillain-Barr&eacute;. Ambos son muy raros</li>
            </ul>
            <p>La influenza (o gripe) es una enfermedad respiratoria com&uacute;n causada por los virus de la influenza.
                Y cualquiera que haya tenido gripe sabe que puede hacer que se sienta bastante enfermo. Pero
                tambi&eacute;n puede causar enfermedades m&aacute;s graves, hospitalizaci&oacute;n e incluso la muerte.
                En los Estados Unidos, aproximadamente 5,000 a 50,000 personas mueren cada a&ntilde;o a causa de la
                gripe. La vacuna contra la gripe es la mejor protecci&oacute;n que tenemos contra los efectos del virus
                de la gripe, y es segura.
                La vacuna contra la gripe existe desde hace d&eacute;cadas, y hay muchas investigaciones que respaldan
                su seguridad y eficacia. Pero es comprensible tener preguntas e inquietudes. Echemos un vistazo
                m&aacute;s de cerca a la investigaci&oacute;n para que pueda tomar la mejor decisi&oacute;n para usted y
                su comunidad.
            </p>
            <h3>¿Se puede enfermar por la vacuna contra la gripe?</h3>
            <p>Algunas personas se sienten un poco mal despu&eacute;s de recibir la vacuna contra la gripe. Sin embargo,
                esto no se debe a que realmente te hayas enfermado con un virus. Cuando recibes la vacuna contra la
                gripe, tu cuerpo responde montando una respuesta inmunitaria. Durante este proceso, tu cuerpo trabaja a
                toda marcha para bombear anticuerpos que te proteger&aacute;n si te expones a la gripe m&aacute;s
                adelante. Esto puede hacer que se sienta levemente enfermo, pero esta sensaci&oacute;n generalmente
                desaparece en un d&iacute;a m&aacute;s o menos.
                Hay efectos secundarios raros que pueden enfermarlo de otras maneras. Pero la probabilidad de una
                reacci&oacute;n grave a la vacuna contra la gripe es de aproximadamente 1 en 1 mill&oacute;n.
            </p>
            <h3>¿Es segura la vacuna contra la gripe?</h3>
            <p>La vacuna contra la gripe es una forma segura y eficaz de protegerse de los peligros de la gripe. La
                vacuna contra la gripe puede evitar que te enfermes, e incluso te protege si a&uacute;n as&iacute;
                terminas contrayendo la gripe.
                Hay muchos conceptos err&oacute;neos sobre la seguridad de la vacuna contra la gripe. La vacuna contra
                la gripe no causa:
            </p>
            <ul>
                <li>Gripe</li>
                <li>Otras infecciones respiratorias</li>
                <li>Autismo</li>
                <li>Par&aacute;lisis de Bell</li>
            </ul>
            <p>Muchas organizaciones de todo el mundo supervisan la seguridad de las vacunas, como el Programa Mundial
                de Seguridad de las Vacunas de la Organizaci&oacute;n Mundial de la Salud (OMS). En los EE. UU., los CDC
                y la FDA mantienen una estrecha vigilancia sobre las vacunas. Si se encuentra un problema, pueden
                informar r&aacute;pidamente a los funcionarios de atenci&oacute;n m&eacute;dica y al p&uacute;blico.
                Los CDC tienen tres sistemas para monitorear la seguridad de las vacunas:
            </p>
            <ul>
                <li>El Sistema de Notificaci&oacute;n de Eventos Adversos a las Vacunas (VAERS, por sus siglas en
                    ingl&eacute;s) es un programa nacional que rastrea los efectos secundarios u otros problemas con las
                    vacunas.
                </li>
                <li>Vaccine Safety Datalink (VSD) es un programa conjunto entre los CDC y otras nueve organizaciones de
                    atenci&oacute;n m&eacute;dica para hacer un seguimiento de la seguridad de las vacunas.
                </li>
                <li>El proyecto de Evaluaci&oacute;n Cl&iacute;nica de la Seguridad de la Inmunizaci&oacute;n (CISA, por
                    sus siglas en ingl&eacute;s) eval&uacute;a la seguridad de las vacunas y realiza investigaciones de
                    alta calidad con los principales centros m&eacute;dicos.
                </li>
            </ul>
            <h3>¿Qu&eacute; efectos secundarios pueden causar las vacunas contra la gripe?</h3>
            <p>Al igual que con cualquier tratamiento m&eacute;dico, existen algunos riesgos al vacunarse contra la
                gripe. Pero el riesgo de problemas de salud a causa de la vacuna contra la gripe es muy bajo. Hay tres
                efectos secundarios principales que debe conocer.
            </p>
            <p>Reacciones no al&eacute;rgicas a la vacuna contra la gripe
                Muchas personas solo tienen efectos secundarios leves de la vacuna contra la gripe, como
                irritaci&oacute;n local o s&iacute;ntomas similares a los de la gripe. Estos s&iacute;ntomas suelen
                desaparecer en 1 o 2 d&iacute;as y pueden incluir:
            </p>
            <ul>
                <li>Dolor en el brazo por la inyecci&oacute;n (m&aacute;s com&uacute;n)</li>
                <li>Picaz&oacute;n</li>
                <li>Fatiga</li>
                <li>Tos</li>
                <li>Fiebre baja</li>
                <li>Dolores corporales</li>
                <li>Cefaleas</li>
            </ul>
            <p>Las reacciones leves son una respuesta normal a los anticuerpos que el cuerpo produce debido a la vacuna.
                Los anticuerpos son las prote&iacute;nas que ayudan al sistema inmunitario a prevenir o combatir las
                infecciones la pr&oacute;xima vez que entre en contacto con la gripe.
                Algunas personas tambi&eacute;n pueden experimentar un breve desmayo despu&eacute;s de recibir la vacuna
                contra la gripe. Esto es especialmente com&uacute;n en los adolescentes. Y en casos muy raros, las
                personas pueden experimentar dolor o rigidez continuos en el lugar de la inyecci&oacute;n.
            </p>
            <h3>Reacciones al&eacute;rgicas a la vacuna contra la gripe</h3>
            <p>Tambi&eacute;n es posible que tengas una reacci&oacute;n al&eacute;rgica a la vacuna contra la gripe. Por
                lo general, esto es causado por una reacci&oacute;n al&eacute;rgica a otra cosa en la vacuna en lugar de
                a los fragmentos del virus de la influenza.
                Las reacciones al&eacute;rgicas a la vacuna contra la gripe pueden variar de leves a graves. Las
                reacciones leves no ponen en peligro la vida. Pueden incluir s&iacute;ntomas como:
            </p>
            <ul>
                <li>Urticaria</li>
                <li>Secreci&oacute;n nasal o tos</li>
                <li>V&oacute;mitos o diarrea</li>
            </ul>
            <p>Una reacci&oacute;n grave, la anafilaxia, es grave y puede poner en peligro la vida. Los s&iacute;ntomas
                de la anafilaxia incluyen:
            </p>
            <ul>
                <li>Hinchaz&oacute;n de la cara, los labios, la lengua o la garganta</li>
                <li>Dificultad para respirar</li>
                <li>Presi&oacute;n arterial peligrosamente baja</li>
            </ul>
            <p>Estas reacciones son poco frecuentes. La probabilidad de tener una reacci&oacute;n al&eacute;rgica es de
                aproximadamente 1 en 1 mill&oacute;n.
            </p>
            <h3>S&iacute;ndrome de Guillain-Barr&eacute;</h3>
            <p>La vacuna contra la gripe tambi&eacute;n se ha relacionado con el s&iacute;ndrome de
                Guillain-Barr&eacute; (SGB). El SGB es una afecci&oacute;n autoinmunitaria grave que causa
                par&aacute;lisis temporal. Por lo general, comienza en las piernas y puede ascender lentamente por el
                cuerpo. A pesar de que estos s&iacute;ntomas mejoran, esta emergencia m&eacute;dica puede ser
                aterradora.
                El riesgo de contraer el SGB a trav&eacute;s de la vacuna contra la gripe es extremadamente
                peque&ntilde;o: alrededor de 1 en 1 mill&oacute;n. Tambi&eacute;n es importante saber que el virus de la
                gripe en s&iacute; mismo causa el SGB en 17 de cada 1 mill&oacute;n de personas. Esto significa que es
                m&aacute;s probable que contraiga el GBS por infectarse con la gripe que por la vacuna.
            </p>
            <h3>¿La vacuna contra la gripe le da gripe?</h3>
            <p>No, no se puede contraer la gripe por la vacuna contra la gripe. La vacuna contra la gripe est&aacute;
                hecha de fragmentos de virus muertos o debilitados que no pueden enfermarlo. Los s&iacute;ntomas leves
                que a veces se desarrollan no significan que vaya a contraer la gripe.
            </p>
            <h3>¿Se puede vacunar contra la gripe cuando se est&aacute; enfermo?</h3>
            <p>Si se siente enfermo, debe hablar con un profesional de la salud sobre sus s&iacute;ntomas antes de
                recibir la vacuna contra la gripe. La mayor&iacute;a de los expertos est&aacute;n de acuerdo en que es
                seguro vacunarse contra la gripe si tiene una enfermedad menor (como secreci&oacute;n nasal o
                resfriado). Sin embargo, es posible que deba posponer la vacuna contra la gripe si tiene una enfermedad
                m&aacute;s grave.
            </p>
            <h3>¿La vacuna contra la gripe es segura para los beb&eacute;s y los ni&ntilde;os?</h3>
            <p>S&iacute;, la vacuna contra la gripe tambi&eacute;n es segura para los ni&ntilde;os. La vacuna contra la
                gripe no enferma a los ni&ntilde;os y no causa autismo. En m&aacute;s de 1,25 millones de ni&ntilde;os,
                no se encontr&oacute; ninguna conexi&oacute;n entre el autismo y las vacunas.
                Tener gripe es peligroso para los ni&ntilde;os. Es por eso que todos los ni&ntilde;os mayores de 6 meses
                deben vacunarse contra la gripe todos los a&ntilde;os. Es la mejor manera de proteger a los ni&ntilde;os
                de enfermedades graves relacionadas con la influenza, hospitalizaciones y muertes.
            </p>
            <h3>¿La vacuna contra la gripe es segura para las mujeres embarazadas?</h3>
            <p>La vacuna contra la gripe se ha estudiado durante d&eacute;cadas en millones de mujeres embarazadas. Es
                seguro y eficaz. Los expertos recomiendan que las mujeres embarazadas se vacunen contra la gripe.
                Contraer la gripe puede ser peligroso tanto para la madre como para el beb&eacute; en crecimiento. Las
                mujeres embarazadas pueden tener complicaciones m&eacute;dicas si contraen la gripe. Y tienen m&aacute;s
                probabilidades de ser hospitalizados o morir a causa de la gripe. La gripe tambi&eacute;n puede causar
                complicaciones en el embarazo como:
            </p>
            <ul>
                <li>Aborto</li>
                <li>Trabajo de parto prematuro</li>
                <li>Nacimiento prematuro</li>
            </ul>
            <p>Tener gripe durante el embarazo puede incluso aumentar el riesgo a largo plazo del beb&eacute; de padecer
                afecciones como:</p>
            <ul>
                <li>Autismo</li>
                <li>Trastorno bipolar</li>
                <li>Depresi&oacute;n</li>
            </ul>
            <p>La vacuna contra la gripe protege tanto a la madre como al beb&eacute; durante el embarazo.
                Tambi&eacute;n proteger&aacute; al beb&eacute; hasta 6 meses despu&eacute;s del nacimiento. Esto es
                especialmente importante porque los beb&eacute;s menores de 6 meses son demasiado peque&ntilde;os para
                recibir la vacuna contra la gripe.</p>

            <h3>¿La vacuna contra la gripe es segura para las personas con afecciones m&eacute;dicas cr&oacute;nicas?
                <img src="../../../assets/newsletter/blog_06_02_vacunacontragripe.jpg" class="img-fluid"
                    title="Segura y necesaria">
            </h3>
            <p>S&iacute;. De hecho, es especialmente importante que las personas con afecciones m&eacute;dicas
                cr&oacute;nicas se vacunen contra la gripe. La gripe tambi&eacute;n puede ser especialmente peligrosa
                para las personas que tienen un alto riesgo de sufrir complicaciones por la gripe. Esto incluye:
            </p>
            <ul>
                <li>Ni&ntilde;os menores de 5 a&ntilde;os, pero especialmente ni&ntilde;os menores de 2 a&ntilde;os</li>
                <li>Adultos de 65 a&ntilde;os en adelante</li>
                <li>Personas con un &iacute;ndice de masa corporal (IMC) de 40 o m&aacute;s</li>
                <li>Personas inmunodeprimidas, incluidas las personas con c&aacute;ncer</li>
                <li>Nativos americanos y nativos de Alaska</li>
                <li>Personas que viven en hogares de ancianos y otros centros de atenci&oacute;n a largo plazo</li>
                <li>Personas sin hogar</li>
                <li>Personas con diabetes</li>
            </ul>
            <p>La gripe tambi&eacute;n puede empeorar ciertas afecciones de salud, como la insuficiencia
                card&iacute;aca, la enfermedad pulmonar obstructiva cr&oacute;nica (EPOC) y el asma.
            </p>
            <h3>¿Puede la vacuna contra la gripe causar demencia de Alzheimer?</h3>
            <p>La vacuna contra la gripe no causa demencia. De hecho, una nueva investigaci&oacute;n sugiere que las
                vacunas regulares contra la gripe pueden protegerlo contra el desarrollo de la enfermedad de Alzheimer.
                En un estudio de 2020, recibir al menos una vacuna contra la gripe disminuy&oacute; los casos de
                Alzheimer en un 17%. Las vacunas repetidas contra la gripe la redujeron en otro 13%.
            </p>
            <h3>¿Deber&iacute;a vacunarse contra la gripe?</h3>
            <p>Cada opci&oacute;n tiene pros y contras, incluida la vacuna contra la gripe. Pero, para la mayor&iacute;a
                de las personas, es probable que la vacuna contra la gripe haga m&aacute;s bien que mal. Los riesgos son
                muy bajos. En comparaci&oacute;n con la vacuna contra la gripe, tener gripe es en realidad bastante
                riesgoso.
            </p>
            <p>Los CDC recomiendan que todas las personas mayores de 6 meses se vacunen contra la gripe todos los
                a&ntilde;os. Estas son las &uacute;nicas excepciones:
            </p>
            <ul>
                <li>Si actualmente est&aacute; enfermo con fiebre, debe esperar para recibir la vacuna contra la gripe.
                    Esto no significa que no pueda recibir la vacuna contra la gripe en alg&uacute;n momento, pero es
                    posible que deba esperar hasta que sus s&iacute;ntomas actuales hayan pasado.
                </li>
                <li>Si tiene antecedentes de s&iacute;ndrome de Guillain-Barr&eacute;, hable con un profesional de la
                    salud antes de vacunarse contra la gripe.
                </li>
                <li>Si tienes antecedentes de una alergia grave y potencialmente mortal a un ingrediente de la vacuna
                    contra la gripe, evita esa vacuna, con la alergia al huevo como excepci&oacute;n. Los CDC
                    todav&iacute;a recomiendan las vacunas contra la gripe para las personas con alergia al huevo.
                </li>
            </ul>
            <h3>Preguntas frecuentes</h3>
            <img src="../../../assets/newsletter/blog_06_03_vacunacontragripe.jpg" class="img-fluid" title="preguntas">
            <h5>¿Qu&eacute; tan com&uacute;n es sentirse mal despu&eacute;s de vacunarse contra la gripe?</h5>
            <p>Es com&uacute;n sentirse un poco mal despu&eacute;s de recibir la vacuna contra la gripe. Es posible que
                sienta algo de dolor alrededor del lugar donde recibi&oacute; la inyecci&oacute;n, o puede que sienta
                algunos dolores corporales o cansancio. Estos s&iacute;ntomas solo significan que la vacuna contra la
                gripe est&aacute; funcionando al hacer que su sistema inmunol&oacute;gico se acelere y produzca
                anticuerpos que lo proteger&aacute;n de la gripe.
            </p>
            <h5>¿Cu&aacute;nto tiempo despu&eacute;s de una inyecci&oacute;n contra la gripe se pueden tener efectos
                secundarios?</h5>
            <p>La mayor&iacute;a de las personas solo experimentan efectos secundarios durante uno o dos d&iacute;as
                despu&eacute;s de recibir la vacuna contra la gripe. Algunas personas solo experimentan s&iacute;ntomas
                durante unas pocas horas
            </p>
            <h5>¿C&oacute;mo puedo aliviar los efectos secundarios de la vacuna contra la gripe?</h5>
            <p>Puedes controlar los efectos secundarios leves de la vacuna contra la gripe tomando medicamentos de venta
                libre como paracetamol (Tylenol) o ibuprofeno (Advil, Motrin). Esto puede ayudar a tratar cualquier
                fiebre o dolor que pueda tener.
            </p>
            <h5>¿Se puede tener una reacci&oacute;n tard&iacute;a a la vacuna contra la gripe?</h5>
            <p>Si bien es poco frecuente, es posible experimentar reacciones tard&iacute;as a la vacuna contra la gripe.
                Las reacciones tard&iacute;as m&aacute;s comunes son el agrandamiento de los ganglios linf&aacute;ticos
                o la erupci&oacute;n cut&aacute;nea. Estos s&iacute;ntomas suelen aparecer a las pocas horas o
                d&iacute;as de recibir la vacuna contra la gripe. Pero es posible que aparezcan de 2 a 3 semanas
                despu&eacute;s.</p>

            <h3>La conclusi&oacute;n</h3>
            <p>Muchas personas evitan la vacuna contra la gripe por temor a que las enferme. Sin embargo, existen muchos
                conceptos err&oacute;neos sobre la seguridad de la vacuna contra la influenza. La vacuna contra la gripe
                es segura, especialmente en comparaci&oacute;n con los riesgos de contraer la gripe. Y las
                complicaciones de salud de la gripe pueden ocurrirle a cualquiera, incluso a las personas sanas.
                Los dos riesgos principales de la vacuna contra la gripe son una reacci&oacute;n al&eacute;rgica y el
                s&iacute;ndrome de Guillain-Barr&eacute;. Si tiene alguna inquietud sobre cualquiera de estos, hable con
                un profesional de la salud. Ellos pueden ayudarlo a tomar la decisi&oacute;n que se considere correcta
                para usted.
            </p>

            <h3>Referencias</h3>
            <p style="font-style: oblique;" class="text-muted">B. J. S., et al. (2019). <a
                    href="https://jamanetwork.com/journals/jamapsychiatry/article-abstract/2727135">Riesgo a largo plazo
                    de enfermedad neuropsiqui&aacute;trica despu&eacute;s de la exposici&oacute;n a una infecci&oacute;n
                    en el &uacute;tero.</a>JAMA Psiquiatr&iacute;a.
            </p>
            <p style="font-style: oblique;" class="text-muted">Asociaci&oacute;n de Alzheimer. (2020). <a
                    href="https://aaic.alz.org/releases_2020/vaccines-dementia-risk.asp">Las vacunas contra la gripe y
                    la neumon&iacute;a se relacionan con un menor riesgo de demencia con Alzheimer.</a>
            </p>

        </div>
        <div class="col-lg-2">
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-8">
            <hr>
            <h4>Acerca del autor:</h4>
            <div class="colorfull-info-container  mt-3">
                <div class="info-content author">
                    <img src="https://media.licdn.com/dms/image/v2/C5603AQHeKfe9rgS49Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593736418716?e=1736985600&v=beta&t=TTLZE_Pfy9aMbs_LvYe7gIDw8mXIQrualDkIYQVmXTA"
                        class="author-image">
                    <div class="info-text">
                        <span>Dr. Javier Le&oacute;n Chavarr&iacute;a</span>
                        Farmac&eacute;utico con m&aacute;s de ocho a&ntilde;os de liderazgo en QOL CLINICAL y el
                        &aacute;mbito
                        acad&eacute;mico, me especializo en
                        la transformaci&oacute;n digital del Seguimiento Farmacoterap&eacute;utico (SFT). Experiencia
                        abarca
                        desde el
                        acompa&ntilde;amiento a la medicaci&oacute;n hasta la implementaci&oacute;n de
                        tecnolog&iacute;as de la
                        informaci&oacute;n en la gesti&oacute;n de
                        salud.
                        <span><a href="https://www.linkedin.com/in/javier-le%C3%B3n-chavarr%C3%ADa/"><i
                                    class="fa fa-linkedin fa-xs green-light-icon-text"></i></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
        </div>
    </div>