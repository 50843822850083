import { NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CountryModel } from 'src/app/models/country-model';
import { ProductModel } from 'src/app/models/product-model';
import { GlobalService } from 'src/app/services/global.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CarouselModule,ButtonModule,NgIf],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.css'
})
export class CarouselComponent {
  responsiveOptions: any[] | undefined;
  private _gobalService= inject(GlobalService);
  private readonly _analyticsService = inject(AnalyticsService);
  country!: CountryModel;
  ngOnInit() {

    this._gobalService.getDefaultCountry().subscribe(data => {
      this.country=data;
      //console.log('Dato actual:', this.country.CurrencyCode);
    });
    this.responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 2
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];
}

productSelected(name: string, provider: string, url:string) {
  this._analyticsService.trackProductSelection(name, provider, url);
}


  @Input() products!:ProductModel[]
}
