<footer class="text-center text-lg-start bg-light text-dark ubico-footer">
    <section class="d-flex justify-content-center justify-content-lg-between p-2 border-bottom">
        <div class="me-5 d-none d-lg-block">
            <span>Con&eacute;ctate con nosotros en las redes sociales:</span>
        </div>
        <div>
            <a href="https://www.facebook.com/lifehubcr/" class="me-4"><i class="fa fa-facebook"></i></a>
            <a href="https://www.instagram.com/lifehubcr/" class="me-4"><i class="fa fa-instagram"></i></a>
            <a href="https://api.whatsapp.com/send/?phone=50689746634" class="me-4"><i class="fa fa-whatsapp"></i></a>
            <a href="" class="me-4"><i class="fa fa-linkedin"></i></a>
        </div>
    </section>
    <section class="">
        <div class="container text-center text-md-start mt-1">
            <div class="row mt-3">
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <a [routerLink]="['/dashboard']" class="logo-footer">
                        <img title="Ubico" src="./assets/UbicoLB.png" width="150px">
                    </a>
                    <p>
                        Acceda a productos de salud esenciales a los precios m&aacute;s accesibles y mas cerca de usted.
                    </p>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Acerca de
                    </h6>
                    <p>
                        <a [routerLink]="['/about']" class="">Quienes Somos?</a>
                    </p>
                    <p>
                        <a [routerLink]="['/faq']" class="">Preguntas Frecuentes</a>
                    </p>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Ayuda
                    </h6>
                    <p>
                        <a [routerLink]="['/contact']" class="">Cont&aacute;ctanos</a>
                    </p>
                    <p>
                        <a [routerLink]="['/blog']" class="">Blog</a>
                    </p>
                    <!-- <p>
                        <a [routerLink]="['/help']" class="">Ayuda</a>
                    </p> -->
                    <p>
                        <a [routerLink]="['/providerRequest']" class="">Agregar mi Farmacia</a>
                    </p>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Contacto
                    </h6>
                    <p><i class="fa fa-home me-3"></i> San Jos&eacute;, San Jos&eacute;, Costa Rica</p>
                    <p>
                        <i class="fa fa-envelope me-3"></i>
                        contact&#64;synergytechnology.net
                    </p>
                    <p><i class="fa fa-phone me-3"></i> + (506) 8603 6047</p>

                    <app-subscribe></app-subscribe>
                </div>
            </div>
        </div>
    </section>
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">© 2024 Todos los derechos reservados:
        <a class="text-reset fw-bold" href="http://synergytechnology.net">Synergy Technology S.A.</a>
    </div>
</footer>