<div class="menubar">
  <nav class="navbar navbar-expand-md navbar-dark bg-primary text-white">
    <a class="navbar-brand" [routerLink]="['/search',defaultCountry?.Name]">
      <img class="img-fluid home" src="../assets/ubico_logo_small_white.png">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar_01"
      aria-controls="navbar_01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse text-center" id="navbar_01">
      <ul class="navbar-nav mr-auto">
        <div class="align">
          <li class="nav-item">
            <a href="" [routerLink]="['/about']" class="nav-link d-none d-md-block" routerLinkActive="active">Acerca</a>
            <a href="" [routerLink]="['/about']" class="nav-link d-block d-md-none" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#navbar_01">Acerca</a>
          </li>
          <li class="nav-item">
            <a href="" [routerLink]="['/faq']" class="nav-link d-none d-md-block" routerLinkActive="active">FAQ</a>
            <a href="" [routerLink]="['/faq']" class="nav-link d-block d-md-none" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#navbar_01">FAQ</a>
          </li>
        </div>
        <div class="align">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown"
              aria-expanded="false">Más Info</a>
            <ul class="dropdown-menu text-center dropdown-menu__item" aria-labelledby="dropdown01">
              <li class="dropdown-menu__item">
                <a href="" [routerLink]="['/blog']" class="dropdown-item d-none d-md-block" routerLinkActive="active" >Blog</a>
                <a href="" [routerLink]="['/blog']" class="dropdown-item d-block d-md-none" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#navbar_01">Blog</a>
              </li>
              <li class="dropdown-menu__item">
                <a href="" [routerLink]="['/contact']" class="dropdown-item d-none d-md-block" routerLinkActive="active" >Contacto</a>
                <a href="" [routerLink]="['/contact']" class="dropdown-item d-block d-md-none" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#navbar_01">Contacto</a>
              </li>
              <li class="dropdown-menu__item">
                <a href="" [routerLink]="['/providerRequest']" class="dropdown-item d-none d-md-block" routerLinkActive="active">Agregar mi Farmacia</a>
                <a href="" [routerLink]="['/providerRequest']" class="dropdown-item d-block d-md-none" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#navbar_01">Agregar mi Farmacia</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle flag-dropdown" href="#" id="dropdown05" data-bs-toggle="dropdown"
              aria-expanded="false">{{defaultCountry?.Name}} <img src="../assets/{{defaultCountry?.FlagImageURL}}"> </a>
            <ul class="dropdown-menu text-center dropdown-menu__item" aria-labelledby="dropdown05">
              <li *ngFor="let country of countries " class="dropdown-menu__item">
                <a [routerLink]="['/search',country.Name]" (click)="ChangeCountry(country.Id)" class="dropdown-item"
                  href="">{{country.Name}} <img src="../assets/{{country.FlagImageURL}}"
                    style="width: 30px; float: right;  color: white !important;"> </a>
              </li>
            </ul>
          </li>
        </div>
      </ul>
    </div>
  </nav>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <app-header></app-header>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>