// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CountryModel } from "src/app/models/country-model";

export const environment = {
  defaultCountry:  "",
  countryFlagURL: "",
  production: false,
  serviceURI: "https://dev.ubicolatam.com/",
  google:{
    apiKey: 'AIzaSyBMskhocIapn0t_iBQt1zwKdAoP2f5-2lo',
  },
  diffbot:{
    token: 'bc2277d2013a8466b1edcf920929af90',
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
