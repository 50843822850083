<div class="row">
    <div class="col-lg-12">
        <h2 class="seccion-header">Ahorre en farmacias populares</h2>
        <div class="interaction-box">
            <p-carousel class="" [value]="providers" [numVisible]="6" [numScroll]="5" [circular]="false"
                [responsiveOptions]="responsiveOptions" autoplayInterval="6000">
                <ng-template let-provider pTemplate="item">
                    <div class="provider-item" [ngClass]="{ 'selected': providerIdSelected === provider.Id }"
                        (click)="ChangeProvider(provider.Id)" (keypress)="KeyPressProvider($event, provider.Id)"
                        tabindex="0">
                        <a class="pointer">
                            <img class="img-fluid provider-logo" src="../../../assets/providers/{{provider.ImageURL}}"
                                title="{{provider.Name}}">
                        </a>
                        <div class="provider-name">{{provider.Name}}</div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
    <div class="col-lg-12 text-center mb-4">
        <app-carousel [products]="products" />
    </div>
</div>