import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResponseModel } from '../models/base-response-model';
import { ProviderModel } from '../models/provider-model';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private serviceURI!: string;
  constructor(private httpClient: HttpClient) {
    this.serviceURI = environment.serviceURI;
  }

  Get(): Observable<BaseResponseModel<ProviderModel>> {
    return this.httpClient.get<BaseResponseModel<ProviderModel>>(this.serviceURI + '/api/Provider/Get',httpOptions);
  }
  GetByCountryId(countryId: number): Observable<BaseResponseModel<ProviderModel>> {
    return this.httpClient.get<BaseResponseModel<ProviderModel>>(this.serviceURI + '/api/Provider/GetByCountryId?countryId=' + countryId,httpOptions);
  }
}
