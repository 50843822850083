import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SearchComponent } from './components/search/search.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { ProductsComponent } from './components/products/products.component';
import { FaqPageComponent } from './components/faq-page/faq-page.component';
import { NewsletterPageComponent } from './components/newsletter-page/newsletter-page.component';
import { BlogPageComponent } from './components/blog-page/blog-page.component';
import { ProviderRequestComponent } from './components/provider-request/provider-request.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'search/:country', component: SearchComponent },
  { path: 'contact', component: ContactPageComponent },
  { path: 'providerRequest', component: ProviderRequestComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'faq', component: FaqPageComponent },
  { path: 'blog', component: BlogPageComponent },
  { path: 'newsletter/:id', component: NewsletterPageComponent },
  { path: 'products/:query/:type', component:ProductsComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true,scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
