<div class="row justify-content-md-center ">
  <!-- desktop -->
  <div class="col-lg-8 d-none d-sm-none d-md-block">
    <div class="row bar-container mb-3" [ngClass]="{'sticky' : isSticky}">
      <div class="col-md-3 ">
        <p-dropdown [options]="searchOptions" [(ngModel)]="selectedOption" placeholder="Buscar por:"
          (onChange)="onChangeFilterType()"></p-dropdown>
      </div>
      <div class="col-md-9 pos-relative">
        <div class="input-group">
          <input type="text" [placeholder]="getPlaceholder()" [(ngModel)]="query" (input)="filter()"
            class="search-input" (keyup.enter)="search()" />
          <button (click)="search()" class="search-button" title="Presione para buscar">
            <i class="fa fa-search"></i>
            Buscar</button>
        </div>
        <div *ngIf="filteredNames.length > 0" class="search-filtered text-center pos-absolute">
          <ul>
            <li *ngFor="let name of filteredNames" (click)="selectName(name)">
              {{name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div [ngClass]="{'bar-container-placeholder' : isSticky}"> </div>
  </div>

  <!-- mobile -->
  <div class="col-lg-2 text-center d-block d-md-none">
    <p-dropdown [options]="searchOptions" [(ngModel)]="selectedOption" placeholder="Buscar por:"
      (onChange)="onChangeFilterType()"></p-dropdown>
  </div>
  <div class="col-lg-8 d-block d-md-none">
    <div class="">
      <input type="text" [placeholder]="getPlaceholder()" [(ngModel)]="query" (input)="filter()" class="search-input"
        (keyup.enter)="search()" />
      <div *ngIf="filteredNames.length > 0" class="search-filtered text-center mb-3">
        <ul>
          <li *ngFor="let name of filteredNames" (click)="selectName(name)">
            {{name}}
          </li>
        </ul>
      </div>
      <button (click)="search()" class="search-button" title="Presione para buscar">
        <i class="fa fa-search"></i> Buscar</button>
    </div>
  </div>
</div>
<div class="row justify-content-md-center">
  <div class="col-lg-6 mb-2">
    <div class="colorfull-info-container">
      <div class="info-content">
        <i class="fa fa-info-circle fa-3x info-icon"></i>
        <p class="info-text">Al ingresar la información, buscaremos los medicamentos en nuestra base de datos de
          farmacias registradas y te mostraremos el mejor precio de acuerdo a tu ubicación, nuestros precios se
          actualizan cada 2 días.</p>
      </div>
    </div>
  </div>
</div>