import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { catchError, finalize } from 'rxjs/operators';
import { ProviderRequestModel } from 'src/app/models/provider-request-model';
import { ProviderRequestService } from 'src/app/services/provider-request.service';

declare var $: any;
@Component({
  selector: 'app-provider-request',
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule, NgIf, ToastModule],
  templateUrl: './provider-request.component.html',
  styleUrl: './provider-request.component.css'
})
export class ProviderRequestComponent implements OnInit {
  requestForm!: FormGroup;
  requestSubmitted: boolean = false;
  loading: boolean = false;
  imageFile: any;
  imageMessage!: string;
  inputFile: any;

  @Input() ClientId: string = '00000000-0000-0000-0000-000000000000';
  @Input() ApplicationId: number = 0;
  @Output() onClick = new EventEmitter<boolean>();

  constructor(private providerRequestService: ProviderRequestService, private messageService: MessageService) { }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.requestForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      managerName: new FormControl(null, [Validators.required]),
      emailAddress: new FormControl(null, [Validators.required, Validators.email]),
      phoneNumber: new FormControl("(506)", [Validators.required, Validators.minLength(11)]),
      imageURL: new FormControl(null),
      base64String: new FormControl(null),
      message: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    this.requestSubmitted = true;
    if (this.requestForm.invalid) {
      return;
    }

    this.loading = true;
    let requestDTO = new ProviderRequestModel();
    requestDTO.Id = '0';
    requestDTO.ClientId = this.ClientId;
    requestDTO.Name = this.requestForm.value.name;
    requestDTO.ManagerName = this.requestForm.value.managerName;
    requestDTO.EmailAddress = this.requestForm.value.emailAddress;
    requestDTO.PhoneNumber = this.requestForm.value.phoneNumber;
    requestDTO.ImageURL = this.requestForm.value.imageURL;
    requestDTO.Base64StringImage = this.requestForm.value.base64String;
    requestDTO.Message = this.requestForm.value.message;
    requestDTO.CreatedBy = '00000000-0000-0000-0000-000000000000';
    requestDTO.CreatedDate = new Date();
    console.log(requestDTO);

    this.providerRequestService.Create(requestDTO).pipe(
      finalize(() => {
        this.requestSubmitted = false;
        this.loading = false;
        this.onClearImage();
      }),
      catchError((error) => {
        this.onClick.emit(false);
        throw error;
      })
    ).subscribe((data) => {
      if (data.IsSuccess) {
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'success', summary: 'Proceso exitoso', detail: 'Su comentario se ha enviado con exito!' });
        this.requestForm.reset({ phoneNumber: "(506)"});
      } else {
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'error', summary: 'Proceso exitoso', detail: 'Se ha producido un error!' });
        this.requestForm.reset({ phoneNumber: "(506)"});
      }
    });
  }
  onReject() {

  }
  onConfirm() {
    this.messageService.clear();
  }

  onSearchImage() {
    $("#inputFile").trigger('click');
  }

  onFileChange(files: any) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.imageMessage = "Tipo de archivo no soportado.";
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.requestForm.controls["imageURL"].setValue(files[0].name);
      this.imageFile = reader.result;
      this.requestForm.controls["base64String"].setValue(this.imageFile.substr(this.imageFile.indexOf(',') + 1));
    }
  }

  onClearImage() {
    $("#inputFile").val('');
    this.imageFile = null;
    this.imageMessage = null!;
    this.requestForm.controls["imageURL"].setValue("");
  }

  get f() { return this.requestForm.controls; }
}