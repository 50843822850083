import { Component, EventEmitter, inject, Output } from '@angular/core';
import { ProductsListComponent } from '../shared/products-list/products-list.component';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { ProductModel } from 'src/app/models/product-model';
import { catchError, finalize } from 'rxjs';
import { NgIf } from '@angular/common';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { GlobalService } from 'src/app/services/global.service';
import { CountryModel } from 'src/app/models/country-model';


@Component({
  selector: 'app-products',
  standalone: true,
  imports: [ProductsListComponent, NgIf],
  templateUrl: './products.component.html',
  styleUrl: './products.component.css'
})
export class ProductsComponent {
  showNotFound: string = "";
  private readonly _analyticsService = inject(AnalyticsService);
  private readonly _productService = inject(ProductService);
  products: ProductModel[] = [];
  private _gobalService= inject(GlobalService);
  defaultCountry!: CountryModel | undefined;
  constructor(private route: ActivatedRoute) {
  }
  ngOnInit() {
    var query = this.route.snapshot.paramMap.get("query") as string;
    var type = this.route.snapshot.paramMap.get("type") as string;
    this._gobalService.getDefaultCountry().subscribe(data => {
      this.defaultCountry=data;
    });

    if (type == 'nombre') {
      this._productService.GetByName(query,this.defaultCountry?.Id!).pipe(
        finalize(() => {
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe((resp: any) => {
        this.products = resp.Data;
        this.checkForNotFount(query);
      });
    } else if (type == 'dolencia') {
      this._productService.GetProductsByRecommendationName(query,this.defaultCountry?.Id!).pipe(
        finalize(() => {
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe((resp: any) => {
        this.products = resp.Data;
        this.checkForNotFount(query);
      });
    }else if (type == 'componente') {
      this._productService.GetProductsByComponentName(query,this.defaultCountry?.Id!).pipe(
        finalize(() => {
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe((resp: any) => {
        this.products = resp.Data;
        this.checkForNotFount(query);
      });
    }
  }

  checkForNotFount(query: string) {
    if (this.products.length == 0) {
      this.showNotFound = query;
      this._analyticsService.trackEvent("PRODUCT_SEARCH_NOT_FOUND", query, "USER_EVENT");
    } else {
      this.showNotFound = "";
    }
  }

}
