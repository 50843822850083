<div class="container-fluid">
  <div class="row">
    <div class="col-lg-2">
      <div class="filters">
        <h2 class="filter-header">Filtrar</h2>
        <div class="filter-section">
          <h3>Farmacias</h3>
          <div *ngFor="let provider of providers; let i = index" class="filter-options">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" (change)="changeProductsByProvider(provider, $event)" id="f{{provider}}">
              <label class="form-check-label" for="f{{provider}}">
                {{provider}}
              </label>
            </div>
          </div>
        </div>
        <div class="filter-section">
          <h3>Precio</h3>
          <div class="filter-options">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="price" (change)="changeProductsByPice('Menor', $event)" value="asc" checked >
              <label class="form-check-label" >
                Menor a mayor
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="price" (change)="changeProductsByPice('Mayor', $event)" value="desc">
              <label class="form-check-label" >
                Mayor a menor
              </label>
            </div>
          </div>
        </div>
        <div class="filter-section">
          <h3>Descuentos</h3>
          <div class="filter-options">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" (change)="changeProductsByDiscount($event)" id="discount-c">
              <label class="form-check-label" for="discount-c">
                Solo Mostrar rebajas
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="container">
        <div class="row results-header">
          <div class="col-md-6">
            <h2>Resultados:</h2>
          </div>
          <div class="col-md-6">
            <span><b>{{products.length}}</b> productos encontrados</span>
          </div>
        </div>
        <p-dataView #dv [value]="products" [sortField]="sortField" [sortOrder]="sortOrder" [paginator]="true"
          [rows]="12" alwaysShowPaginator="false" layout="grid" >
          <ng-template let-products pTemplate="gridItem">
            <ul class="grid">
              <li *ngFor="let product of products; let i = index" class="item">
                  <div *ngIf="product.PriceWithDiscount>0" class="discount">{{product.Discount}}%</div>

                  <div class="product-image">
                    <img [src]="product.ImageURL" title="{{ product.Name }}" width="180" height="180">
                  </div>
                  <h5 class="product-name">{{product.Name}}</h5>
                  <p class="pharmacy">Farmacia: {{product.ProviderName}}</p>
                  <p *ngIf="product.PriceWithDiscount>0; else NoDiscount" class="price">{{ product.PriceWithDiscount |
                    currency: this.country.CurrencyCode }}</p>
                  <ng-template #NoDiscount>
                    <p class="price">{{ product.Price | currency: this.country.CurrencyCode }}</p>
                  </ng-template>
                  <a class="cta" href="{{product.ProductURL}}" target="_blank"
                  (click)="productSelected(product.Name,product.ProviderName,product.ProductURL)">Ir a Farmacia<i
                      class="fa fa-external-link external-link" aria-hidden="true"></i></a>
              </li>
            </ul>
          </ng-template>
        </p-dataView>
      </div>
    </div>
    <div class="col-lg-2" [class]="className">
      <!-- <app-login></app-login> -->
    </div>
  </div>
</div>
