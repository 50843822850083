import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input, ViewChild } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { CountryModel } from 'src/app/models/country-model';
import { ProductModel } from 'src/app/models/product-model';
import { GlobalService } from 'src/app/services/global.service';
import { DropdownModule } from 'primeng/dropdown';
import { LoginComponent } from '../../login/login.component';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-products-list',
  standalone: true,
  imports: [DataViewModule, NgFor, NgIf, DropdownModule, CurrencyPipe, LoginComponent],
  templateUrl: './products-list.component.html',
  styleUrl: './products-list.component.css'
})
export class ProductsListComponent {
  @Input() products: ProductModel[] = [];
  private _gobalService = inject(GlobalService);
  private readonly _analyticsService = inject(AnalyticsService);

  country!: CountryModel;
  providers: string[] = [];
  providersSelected: string[] = [];
  productsBackup: ProductModel[] = [];
  sortField: string = 'Price';
  sortOrder: number = 1; // Orden ascendente (1 para asc, -1 para desc)
  discountFilter: boolean = false;
  productsDiscount: ProductModel[] = [];
  className: string = "";
  @ViewChild('dv') dataView: any;

  ngOnInit() {
    this._gobalService.getDefaultCountry().subscribe(data => {
      this.country = data;
      this.className = "bg_" + this.country.Name.replace(" ","") + "C";
    });
    if (this.products.length > 0) {
      this.productsBackup = this.products;
      //Set para obtener valores unicos
      this.providers = Array.from(new Set(this.products.map(product => product.ProviderName)));
    }
  }


  changeProductsByPice(price: string, $event: any) {
    if (price == 'Menor') {
      this.sortOrder = 1;
    } else {
      this.sortOrder = -1;
    }
  }
  changeProductsByProvider(provider: string, $event: any) {
    this._analyticsService.trackEvent("PRODUCT_FILTER_SELECTED", provider, "USER_INTERACTION");
    if ($event.target.checked) {
      this.providersSelected.push(provider);
    } else {
      this.providersSelected = this.providersSelected.filter(p => p !== provider);
    }

    if (this.providersSelected.length > 0) {
      if (this.discountFilter == false) {
        this.products = this.productsBackup.filter(product =>
          this.providersSelected.includes(product.ProviderName)
        );
      } else {
        this.products = this.productsDiscount.filter(product =>
          this.providersSelected.includes(product.ProviderName)
        );
      }
      this.dataView.first = 0;
    } else {
      if (this.discountFilter == false) {
        this.products = this.productsBackup;
      } else {
        this.products = this.productsDiscount;
      }

    }
  }

  changeProductsByDiscount($event: any) {
    if ($event.target.checked) {
      this.discountFilter = true
      this.productsDiscount = this.products.filter(product => product.PriceWithDiscount > 0);
      this.products = this.productsDiscount;
    } else {
      this.discountFilter = false
      if (this.providersSelected.length > 0) {
        this.products = this.productsBackup.filter(product =>
          this.providersSelected.includes(product.ProviderName)
        );
      } else {
        this.products = this.productsBackup;
      }
    }

  }

  productSelected(name: string, provider: string, url:string) {
    this._analyticsService.trackProductSelection(name, provider, url);
  }

}
