<div class="container-fullwidth">

  <!-- seccion descuentos -->
  <div class="row">
    <div class="col-lg-2 text-center" [class]="className"></div>
    <div class="col-lg-8">
      <app-discount-section />
    </div>
    <div class="col-lg-2">
      <!-- <app-login></app-login> -->
    </div>
  </div>

  <!-- seccion blog -->
  <div class="row justify-content-md-center">
    <div class="col-lg-8">
      <!-- <h2 class="seccion-header">Blog</h2> -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12" style="padding-left: 0px; padding-right: 0px;">
      <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
            aria-label="Slide 1" aria-current="true"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4"
            aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5"
            aria-label="Slide 6"></button>
        </div>
        <div class="carousel-inner">
          <a href="" [routerLink]="['/newsletter',1]">
            <div class="carousel-item active" data-bs-interval="4000" title="Cuanto dura la conjuntivitis?">
              <img src="../../../assets/newsletter/blog_01_01_conjuntivitis.jpg" class="img-fluid"
                title="Cuanto dura la conjuntivitis?">
            </div>
          </a>

          <a href="" [routerLink]="['/newsletter',2]">
            <div class="carousel-item" data-bs-interval="4000" title="Resfriados?">
              <img src="../../../assets/newsletter/blog_02_01_resfriado.jpg" class="img-fluid" title="Resfriados?">
            </div>
          </a>

          <a href="" [routerLink]="['/newsletter',3]">
            <div class="carousel-item" data-bs-interval="4000"
              title="Lantus vs. Ozempic: 6 diferencias a tener en cuenta">
              <img src="../../../assets/newsletter/blog_03_01_lantusVSozempic.jpg" class="img-fluid"
                title="Lantus vs. Ozempic: 6 diferencias a tener en cuenta">
            </div>
          </a>


          <a href="" [routerLink]="['/newsletter',4]">
            <div class="carousel-item" data-bs-interval="4000"
              title="7 formas de prevenir un resfriado cuando lo sientes venir">
              <img src="../../../assets/newsletter/blog_04_01_prevenirResfriado.jpg" class="img-fluid"
                title="7 formas de prevenir un resfriado cuando lo sientes venir">
            </div>
          </a>

          <a href="" [routerLink]="['/newsletter',5]">
            <div class="carousel-item" data-bs-interval="4000"
              title="Las 6 mejores cosas para beber cuando tienes diarrea">
              <img src="../../../assets/newsletter/blog_05_01_diarrea.jpg" class="img-fluid"
                title="Las 6 mejores cosas para beber cuando tienes diarrea">
            </div>
          </a>

          <a href="" [routerLink]="['/newsletter',6]">
            <div class="carousel-item" data-bs-interval="4000"
              title="¿La vacuna contra la gripe puede enfermarte?">
              <img src="../../../assets/newsletter/blog_06_01_vacunacontragripe.jpg" class="img-fluid"
                title="¿La vacuna contra la gripe puede enfermarte?">
            </div>
          </a>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <!-- seccion iconos -->
  </div>
  <div class="row justify-content-md-center mt-4">
    <div class="col-lg-8">
      <h2 class="seccion-header text-center">Encuentre sus medicamentos al mejor precio cerca de usted</h2>
      <div class="country-grid">
        <span class="pointer">
          <div class="search-card fade-in-up" style="animation-delay: 0.1s;">
            <img src="../../../assets/iconos pagina web - ubico_Liquidaciones.png" title="Liquidaciones"
              class=" img-fluid search-flag">
            <h3 class="country-name green-icon-text">Liquidaciones</h3>
          </div>
        </span>
        <div class="search-card fade-in-up" style="animation-delay: 0.2s;">
          <img src="../../../assets/iconos pagina web - ubico_Medicamentos.png" title="Medicamentos"
            class=" img-fluid search-flag">
          <h3 class="country-name orange-icon-text">Medicamentos</h3>
        </div>
        <div class="search-card fade-in-up" style="animation-delay: 0.3s;">
          <img src="../../../assets/iconos pagina web - ubico_Suplementos.png" title="Suplementos y Vitaminas"
            class=" img-fluid search-flag">
          <h3 class=" country-name rose-icon-text">Suplementos y Vitaminas</h3>
        </div>
        <div class="search-card fade-in-up" style="animation-delay: 0.4s;">
          <img src="../../../assets/iconos pagina web - ubico_maternidad.png" title="Infantil y Maternidad"
            class=" img-fluid search-flag blue-icon-text">
          <h3 class="country-name blue-icon-text">Infantil y Maternidad</h3>
        </div>
      </div>
    </div>
    <div class="col-lg-5 mt-4 mb-3">
      <div class="colorfull-info-container">
        <div class="info-content">
          <i class="fa fa-info-circle fa-3x info-icon"></i>
          <div class="info-text">
            <span>Ahorro y Sostenibilidad</span>
            Cuando los productos tienen grandes descuentos es posible que se encuentren próximos a vencer, por lo que al
            comprarlos está evitando que deban ser destruidos y esto representa una gran carga ambiental.
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- seccion como funciona -->
  <div class="row justify-content-md-center">
    <div class="col-lg-8">
      <h2 class="seccion-header">¿Como funciona Ubico?</h2>
      <div class="row mb-4">
        <div class="col-sm-4">
          <div class="card" style="margin-top: 20px;">
            <div class="card-body">
              <img class="img-fluid" title="Ubico - Comparador de precios" width="500px"
                src="../../../assets/como_funciona/1_busque.png">

              <h5 class="card-title">Busque el medicamento</h5>
              <p class="card-text">
                Utilice nuestra barra de b&uacute;squeda para encontrar todas las opciones y
                precios disponibles.
              </p>

            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card" style="margin-top: 20px;">
            <div class="card-body">
              <img class="img-fluid" title="Ubico - Comparador de precios" width="500px"
                src="../../../assets/como_funciona/2_seleccione.png">
              <h5 class="card-title">Seleccione el precio mas barato</h5>
              <p class="card-text">
                Le mostraremos el valor del medicamento en todas las farmacias de su
                pa&iacute;s para que pueda comparar sus precios.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card" style="margin-top: 20px;">
            <div class="card-body">
              <img class="img-fluid" title="imagen ahorrar" width="500px"
                src="../../../assets/como_funciona/3_ahorre.png">
              <h5 class="card-title">Ahorre</h5>
              <p class="card-text">
                Desde Ubico puede ir directamente al sitio web del medicamento en la farmacia
                seleccionada con tan solo un click.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-sm-center mb-5">
        <a href="" [routerLink]="['/faq']" class="btn btn-primary more-button">Más Información</a>
      </div>
    </div>
  </div>

</div>