<div class="container-fluid about">
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <img src="../../../assets/about/ubico_about_banner.png" class="img-fluid mb-4">
            <h1 class="seccion-header">¿Qui&eacute;nes Somos?</h1>
            <p>Ubico es un innovador comparador de precios de medicamentos en Costa Rica, dise&ntilde;ado para
                empoderar a
                los Usuarios de Medicamentos y mejorar el acceso a productos de salud esenciales. Nuestra plataforma se
                centra en proporcionar informaci&oacute;n clara y
                precisa sobre los precios de medicamentos, suplementos y otros productos sanitarios, facilitando
                as&iacute; decisiones de compra informadas.</p>

            <h3>¿Por qu&eacute; Ubico?</h3>
            <p>En un contexto donde la transparencia y la accesibilidad son fundamentales, Ubico nace con el objetivo de
                transformar la experiencia de compra en el sector farmac&eacute;utico. Nuestra misi&oacute;n es:</p>
            <ul>
                <li>Promover la Transparencia: Ofrecemos datos actualizados sobre precios y disponibilidad, permitiendo
                    a los usuarios comparar opciones y encontrar las mejores ofertas en el mercado.</li>
                <li>Empoderar a los Usuarios de Medicamentos: Al brindar informaci&oacute;n clara, ayudamos a los
                    consumidores a tomar decisiones informadas que impacten positivamente su salud y bienestar.</li>
                <li>Reducir el Desperdicio: Con un modelo de negocio que incluye la venta de medicamentos con fechas de
                    caducidad cercanas a precios reducidos, contribuimos a minimizar el desperdicio de productos
                    farmac&eacute;uticos.</li>
            </ul>

            <img src="../../../assets/about/ubico_about_icons.png" class="img-fluid mt-3 mb-3">

            <h3>Nuestra Importancia</h3>
            <p>Ubico no solo es una herramienta para encontrar precios competitivos, sino que tambi&eacute;n
                act&uacute;a como un catalizador para la equidad en el acceso a la salud en Centroam&eacute;rica. Al
                centralizar la informaci&oacute;n de precios, buscamos:</p>
            <ul>
                <li>Fomentar la Competencia: Al facilitar la comparaci&oacute;n de precios, incentivamos a los
                    proveedores a ofrecer productos de mejor calidad y a precios más justos.</li>
                <li>Contribuir a la Salud P&uacute;blica: Nuestra plataforma se convierte en un recurso clave para
                    Usuarios de Medicamentos, mejorando el acceso a tratamientos necesarios y promoviendo la salud en la
                    regi&oacute;n.</li>
                <li>Impulsar la Innovaci&oacute;n: A trav&eacute;s de la integraci&oacute;n de tecnolog&iacute;as
                    avanzadas y la colaboraci&oacute;n con diversas entidades, nos aseguramos de que Ubico se mantenga a
                    la vanguardia en el sector de la e-salud.</li>
            </ul>


            <h3>&Uacute;nete a Nuestra Misi&oacute;n</h3>
            <p>Te invitamos a explorar nuestra plataforma y descubrir c&oacute;mo Ubico puede ayudarte a acceder a los
                mejores precios en medicamentos y productos de salud. Juntos, podemos
                construir un futuro más saludable y accesible para todos.</p>

            <img src="../../../assets/about/ubico_about_colors.png" class="img-fluid mt-3 mb-5">
        </div>
        <div class="col-lg-2"></div>
    </div>
</div>