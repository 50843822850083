import { HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponseModel } from '../models/base-response-model';
import { SubscribeModel } from '../models/subscribe-model';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  private serviceURI: string;
  constructor(private httpClient: HttpClient) {
    this.serviceURI = environment.serviceURI;
  }

  Create(model: SubscribeModel): Observable<BaseResponseModel<SubscribeModel>> {
    console.log(model);
    return this.httpClient.post<BaseResponseModel<SubscribeModel>>(this.serviceURI + '/Subscribe',
      JSON.stringify(model), httpOptions);
  }
}