import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResponseModel } from '../models/base-response-model';
import { ProductModel } from '../models/product-model';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private serviceURI!: string;
  constructor(private httpClient: HttpClient) {
    this.serviceURI = environment.serviceURI;
  }

  GetRandomProducts(): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + '/api/Product/GetRandomProducts',httpOptions);
  }

  GetRandomProductsByProviderId(providerID:string): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetRandomProductsByProviderId?providerID=${providerID}`,httpOptions);
  }

  GetByName( name:string,countryId:string): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetByName?name=${name}&countryId=${countryId}`,httpOptions);
  }

  GetProductsByRecommendationName( name:string,countryId:string): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetProductsByRecommendationName?name=${name}&countryId=${countryId}`,httpOptions);
  }

  GetProductsByComponentName( name:string,countryId:string): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetProductsByComponentName?name=${name}&countryId=${countryId}`,httpOptions);
  }
  GetProductMasterByName( name:string): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetProductMasterByName?name=${name}`,httpOptions);
  }

  GetDiscountProducts(countryId:string ): Observable<BaseResponseModel<ProductModel>> {
    return this.httpClient.get<BaseResponseModel<ProductModel>>(this.serviceURI + `/api/Product/GetDiscountProducts?countryId=${countryId}`,httpOptions);
  }

}
