import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { GlobalService } from 'src/app/services/global.service';
import { CountryModel } from 'src/app/models/country-model';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, SubscribeComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  defaultCountry!: CountryModel;

  constructor(private _gobalService: GlobalService) { }

  ngOnInit() {

    this._gobalService.getDefaultCountry().subscribe(data => {
      this.defaultCountry = data;
    });
  }
}
